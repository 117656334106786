import React, { Component } from 'react'
import { connect } from "react-redux";
//import { Link } from "react-router-dom"
import { Button } from "@headlessui/react";
import userActions from "../../redux/user/actions";

const {
  userAmazonAuthStart,
} = userActions;

class Dashboard extends Component {
  openUrl = (res) => {
    window.open(res.data.data.url, "_self")
  }

  render() {
    const { userData } = this.props;

    return (
      <div>
        <h1 className="font-semibold text-xl mb-5 uppercase">Dashboard</h1>
        <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          <div className="flex items-center justify-between">
            <span>You don't have Amazon account connected, please connect 2D BoxContent with your Amazon account.</span>
            {/*
            <Link to="/app/connect" className="btn-primary btn-link">Connect Account</Link>
            */}
            <Button
              className="btn-primary btn-link"
              disabled={this.props.auth_working}
              onClick={() => {this.props.userAmazonAuthStart(
                {},
                {
                  success: [
                    {
                      function: this.openUrl,
                      params: [],
                    }
                  ],
                }
              )}}
            >Connect Account</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    auth_working: state.User.get("auth_working"),
    auth_init_url: state.User.get("auth_init_url"),
  }),
  {
    userAmazonAuthStart,
  }
)(Dashboard);
