import React, { Component } from 'react'
import { Button, Field, Input, Label } from "@headlessui/react"
import { Link } from "react-router-dom";

class ResetPassword extends Component {
  state = {
    pw: '',
    show_pw: false,
    pwd_change_success: false,
    pw_change_loading: false
  }

  changePassword = () => {
    this.setState({ pw_change_loading: true })
    setTimeout(() => this.setState({
      pw_change_loading: false,
      pwd_change_success: true
    }), 1500)
  }

  render() {
    return (
      <div className="container mx-auto flex flex-col h-screen items-center justify-center gap-8">
        <div className="font-bold text-lg">
            2D BoxContent
          </div>
        <div className="px-8 py-8 rounded-md shadow-lg flex flex-col gap-3 w-96 bg-white justify-center">
          <h2 className="font-semibold text-xl">Create a new password</h2>
          {!this.state.pwd_change_success ?
          <>
            <Field className="field">
              <Label>Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  autoComplete="new-password"
                  placeholder="Provide password"
                  onChange={e => this.setState({ pw: e.target.value })}
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Field className="field mt-4">
              <Label>Confirm Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  placeholder="Confirm password"
                  onChange={e => this.setState({ pw: e.target.value })}
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Button
              className="btn-primary mt-4"
              disabled={this.state.pw_change_loading}
              onClick={this.changePassword}
            >Reset Password</Button>
          </>
          :
          <>
            <h2 className="font-semibold text-green-700">Success!</h2>
            <p className="text-slate-500 mb-4">Your password has been successfully changed. Please login with the new password.</p>
            <Link to="/login" className="link">Login</Link>
          </>
          }
        </div>
      </div>
    )
  }
}

export default ResetPassword