const userActions = {
	USER_GET_DATA: 'USER_GET_DATA',
	USER_GET_DATA_SUCCESS: 'USER_GET_DATA_SUCCESS',
	USER_GET_DATA_ERROR: 'USER_GET_DATA_ERROR',

	USER_UPDATE_DATA: 'USER_UPDATE_DATA',
	USER_PASSWORD_RESET: 'USER_PASSWORD_RESET',

  USER_UPDATE_SETTINGS: 'USER_UPDATE_SETTINGS',

  USER_ADDRESS_ALL: 'USER_ADDRESS_ALL',
  USER_ADDRESS_ADD: 'USER_ADDRESS_ADD',
  USER_ADDRESS_DELETE: 'USER_ADDRESS_DELETE',
  USER_ADDRESS_UPDATE: 'USER_ADDRESS_UPDATE',
  USER_AMAZON_AUTH_START: 'USER_AMAZON_AUTH_START',

  userAmazonAuthStart: (data, additional_actions) => ({
    type: userActions.USER_AMAZON_AUTH_START,
    data,
    additional_actions,
  }),

  userAddressUpdate: (data, additional_actions) => ({
    type: userActions.USER_ADDRESS_UPDATE,
    data,
    additional_actions,
  }),

  userAddressDelete: (data, additional_actions) => ({
    type: userActions.USER_ADDRESS_DELETE,
    data,
    additional_actions,
  }),

  userAddressAdd: (data, additional_actions) => ({
    type: userActions.USER_ADDRESS_ADD,
    data,
    additional_actions,
  }),

  userGetAddressAll: (data, additional_actions) => ({
    type: userActions.USER_ADDRESS_ALL,
    data,
    additional_actions,
  }),

  userUpdateSettings: (data) => ({
    type: userActions.USER_UPDATE_SETTINGS,
    data
  }),

	userPasswordReset: (data) => ({
		type: userActions.USER_PASSWORD_RESET,
    data
	}),

	userUpdateData: (data) => ({
	  type: userActions.USER_UPDATE_DATA,
    data,
	}),

	userGetData: () => ({
		type: userActions.USER_GET_DATA,
	}),
	userGetDataSuccess: data => ({
		type: userActions.USER_GET_DATA_SUCCESS,
		data
	}),
	userGetDataError: () => ({
		type: userActions.USER_GET_DATA_ERROR,
	}),

}

export default userActions;
