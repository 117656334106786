import React, { Component } from "react"
import { connect } from "react-redux";
import Loading from "../../components/Loading"
import { Button, Input } from "@headlessui/react"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { flagCA, flagGB, flagUS } from "../../assets/images"
import { ReactComponent as IconTrash } from "../../assets/icons/icon-trash.svg"
import { ReactComponent as IconCopy } from "../../assets/icons/icon-copy.svg"
import { ReactComponent as IconDownload } from "../../assets/icons/icon-download.svg"
import { ReactComponent as IconShipmentCheck } from "../../assets/icons/icon-shipment-check.svg"
import { Tooltip } from "react-tooltip"
import AddressPopover from "../../components/AddressPopover"
import Pagination from "../../components/Pagination"
import PopoverOnHover from "../../components/PopoverOnHover"
import shipmentActions from "../../redux/shipment/actions";
import moment from "moment-timezone";

const {
  shipmentGetActiveAll,
} = shipmentActions;

const flagMap = {
  'US' : flagUS,
  'CA' : flagCA,
  'GB' : flagGB
}

class ActiveShipments extends Component {
  state = {
    page: 1,
  }

  componentDidMount() {
    this.props.shipmentGetActiveAll();
  }

  shipmentTotalQty = (shipment) => {
    return shipment?.items?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);
  }

  shipmentInBoxesQty = (shipment) => {
    return shipment?.boxes?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);
  }

  shipmentTotalWeight = (shipment) => {
    return shipment?.boxes?.map(item => {
      return item.weight;}
    ).reduce((a, b) => a + b, 0);
  }

  shipmentTotalSKUs = (shipment) => {
    return shipment?.items ?  shipment?.items.length : 0
  }

  displayProgress = (shipment) => {
    const shipment_total_qty = this.shipmentTotalQty(shipment);
    const shipment_in_boxes_qty = this.shipmentInBoxesQty(shipment);
    return (
      <React.Fragment>
        <div className="px-1 h-2 relative bg-slate-700 text-xs text-white rounded overflow-clip text-center">
          <div
            className="bg-green-700 absolute left-0"
            style={{ width: `${shipment_total_qty ? shipment_in_boxes_qty/shipment_total_qty*100 : 0}%` }}
          >&nbsp;</div>
        </div>
        <div className="z-10 relative text-slate-500 text-center mt-1">{shipment_in_boxes_qty}/{shipment_total_qty}</div>
      </React.Fragment>
    )
  }

  render() {
    const { shipments_active, shipments_active_working } = this.props;

    return (
      <div>
        <h1 className="font-semibold text-xl uppercase mb-5">Active Shipments</h1>
        {shipments_active_working ?
        <Loading />
        :
        <>
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          {shipments_active?.data?.length > 0 ?
          <>
            <div>
              <div className="flex items-center gap-2 mb-6">
                <Input type="text" placeholder="Shipment ID/Name" className="min-w-96" />
                <Button className="btn-tertiary">Search</Button>
              </div>
            </div>
            <table className="table-auto w-full border-spacing-5 border-collapse text-[15px]">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="px-3">SHIPMENT ID</th>
                  <th className="px-3">SHIPMENT NAME</th>
                  <th className="px-3">DESTINATION</th>
                  <th className="px-3">PROGRESS</th>
                  <th className="px-3">SHIP-FROM</th>
                  <th className="px-3">TYPE</th>
                  <th className="px-3">PACK. TYPE</th>
                  <th className="px-3">CREATED</th>
                  <th className="px-3"></th>
                </tr>
              </thead>
              <tbody>
                {shipments_active?.data?.map(item => (
                <tr key={`shipment-${item.id}`} className="border-t py-3 border-slate-200">
                  <td className="p-3">
                    <Link to={`/app/shipment/active/view/${item.id}`}>{item.shipment_confirmation_id}</Link>
                  </td>
                  <td className="p-3">
                    <PopoverOnHover
                      buttonText={item.name}
                      showPopover={this.state.showPopoverId === item.id}
                      onMouseEnter={() => this.setState({ showPopoverId: item.id })}
                      onMouseLeave={() => this.setState({ showPopoverId: null })}
                    >
                      <div className="grid grid-cols-2 gap-2">
                        {[
                          {title: 'SKUs', value: this.shipmentTotalSKUs(item)},
                          {title: 'Units', value: this.shipmentTotalQty(item)},
                          {title: 'Boxes', value: item?.boxes ? item?.boxes.length : 0},
                          {title: 'Total Weight (lbs)', value: this.shipmentTotalWeight(item)}
                        ].map(infoItem => (
                          <div key={`shipment-${item.shipment_id}-metric-${infoItem.title}`} className="text-left">
                            <span>{infoItem.value ? infoItem.value : 'N/A'}</span>
                            <div className="text-slate-500 text-xs">{infoItem.title}</div>
                          </div>
                        ))}
                      </div>
                    </PopoverOnHover>
                  </td>
                  <td className="p-3">
                    <div className="flex">
                      <img src={flagMap[item.destination_address?.countryCode]} alt="" className="w-4 mr-2" />
                      <span className="leading-none">{item.warehouse_id}<br/>{`${item.destination_address?.city}, ${item.destination_address?.stateOrProvinceCode}`}</span>
                    </div>
                  </td>
                  <td className="p-3">
                    {this.displayProgress(item)}
                  </td>
                  <td className="p-3">
                    <div>{item.source_address?.name}</div>
                    <AddressPopover
                      selectedAddress={item.source_address}
                      select={selected => console.log(selected)}
                      buttonText="Change"
                    />
                  </td>
                  <td className="p-3">{item?.shipment?.shipment_type_human}</td>
                  <td className="p-3">{item?.shipment?.packing_type_human}</td>
                  <td className="p-3">{moment(item.created_at).format("LLL")}</td>
                  <td className="p-3">
                    <div className="flex items-center gap-4">
                      <IconShipmentCheck className="size-4 text-green-700" role="button" data-tooltip-id="t-markshipped" />
                      <IconCopy className="size-4 text-slate-500" role="button" data-tooltip-id="t-duplicate" />
                      <IconDownload className="size-4 text-slate-500" role="button" data-tooltip-id="t-download" />
                      <IconTrash className="size-4 text-red-700" role="button" data-tooltip-id="t-delete" />
                      <Tooltip id="t-markshipped" content="Mark as Shipped" />
                      <Tooltip id="t-duplicate" content="Duplicate Shipment" />
                      <Tooltip id="t-download" content="Download Picklist" />
                      <Tooltip id="t-delete" content="Delete Shipment" />
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </>
          : <p className="text-slate-500">Currently there is no active shipments.</p>
          }
          </div>
          <div className="flex justify-end mt-4">
            <Pagination
              data={{ page: 1, pages: 2 }}
              //callback={}
            />
          </div>
        </>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    shipments_active_working: state.Shipment.get("shipments_active_working"),
    shipments_active: state.Shipment.get("shipments_active"),
  }),
  {
    shipmentGetActiveAll,
  }
)(ActiveShipments);
