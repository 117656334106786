import { Button, Field, Input, Label } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import { flagCA, flagGB, flagUS } from "../assets/images"
//import { Link } from "react-router-dom"
import Select from 'react-select'
import userActions from "../redux/user/actions";

const {
  userUpdateData
} = userActions;

/*
const connectedMarketplaces = [
  {seller_id: 'A17BT9GH6RE00Y' , marketplace: 'us', name: 'My primary store'},
  {seller_id: 'B00YT9GH6REZZY', marketplace: 'ca', name: 'My secondary store'},
]
*/

const flagMap = {
  'us' : flagUS,
  'ca' : flagCA,
  'gb' : flagGB
}

const marketplaces_options = [
  {value: 'ATVPDKIKX0DER', label: <span className="flex gap-2"><img src={flagMap['us']} className="w-7" alt="" /> Amazon.com (United States)</span>},
  //{value: 'A2EUQ1WTGCTBG2', label: <span className="flex gap-2"><img src={flagMap['ca']} className="w-7" alt="" /> Amazon.ca (Canada)</span>},
  //{value: 'A1F83G8C2ARO7P', label: <span className="flex gap-2"><img src={flagMap['gb']} className="w-7" alt="" /> Amazon.co.uk (United Kingdom)</span>},
]

class Account extends Component {
  state = {
    show_pw: false,
    account_data: null,
    pw_data: null,
  }

  updateAccountData = (key, value) => {
    var account_data = {}
    if(this.state.account_data){
      account_data = {...this.state.account_data}
    }
    account_data[key] = value;
    this.setState({account_data: {...account_data}})
  }

  updatePWData = (key, value) => {
    var pw_data = {}
    if(this.state.pw_data){
      pw_data = {...this.state.pw_data}
    }
    pw_data[key] = value;
    this.setState({pw_data: {...pw_data}})
  }

  render() {
    const { userData, user_data_saving } = this.props;
    const { account_data, pw_data } = this.state;
    console.log(pw_data);

    return (
      <div>
        <h1 className="font-semibold text-xl mb-5 uppercase">My Account</h1>
        <div className="flex gap-6">
          <div className="basis-1/2">
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
              <h2 className="font-medium mb-5">Account Information</h2>
              <Field className="field">
                <Label>Username</Label>
                <Input
                  type="text"
                  readOnly
                  disabled={true}
                  defaultValue={userData?.username}
                />
              </Field>
              <Field className="field mt-4">
                <Label>Email</Label>
                <Input
                  type="email"
                  defaultValue={userData?.email}
                  disabled={true}
                  readOnly
                />
              </Field>
              <Field className="field mt-4">
                <Label>Marketplace</Label>
                <Select
                  options={marketplaces_options}
                  value={marketplaces_options.find(m => m.value === userData?.marketplace_id)}
                  onChange={(e) => this.updateAccountData("marketplace_id", e.value)}
                  disabled={user_data_saving}
                />
              </Field>
              <Field className="field">
                <Label>Amazon Seller ID</Label>
                <Input
                  type="text"
                  defaultValue={userData?.seller_id}
                  onChange={(e) => this.updateAccountData("seller_id", e.target.value)}
                  disabled={user_data_saving}
                />
              </Field>
              <Button
                className="btn-primary mt-4"
                onClick={() => this.props.userUpdateData({...account_data})}
                disabled={user_data_saving || !account_data}
              >
                Update
              </Button>
            </div>
          </div>
          <div className="basis-1/2">
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
              <h2 className="font-medium mb-5">Change Password</h2>
              <Field className="field">
                <Label>Current Password</Label>
                <div className="relative">
                  <Input
                    type={this.state.show_pw ? 'text' : 'password'}
                    className="w-full"
                    autoComplete="new-password"
                    placeholder="Provide current password"
                    onChange={e => this.updatePWData("old_password", e.target.value)}
                  />
                  <small
                    className="absolute top-3 right-2 cursor-pointer text-slate-500"
                    onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                  >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
                </div>
              </Field>
              <div className="grid grid-cols-2 gap-8 mt-4">
                <Field className="field">
                  <Label>New Password</Label>
                  <div className="relative">
                    <Input
                      type={this.state.show_pw ? 'text' : 'password'}
                      className="w-full"
                      autoComplete="new-password"
                      placeholder="Provide new password"
                      onChange={e => this.updatePWData("new_password", e.target.value)}
                    />
                    <small
                      className="absolute top-3 right-2 cursor-pointer text-slate-500"
                      onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                    >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
                  </div>
                </Field>
                <Field className="field">
                  <Label>Confirm Password</Label>
                  <div className="relative">
                    <Input
                      type={this.state.show_pw ? 'text' : 'password'}
                      className="w-full"
                      autoComplete="new-password"
                      placeholder="Confirm new password"
                      onChange={e => this.updatePWData("confirm_new_password", e.target.value)}
                    />
                    <small
                      className="absolute top-3 right-2 cursor-pointer text-slate-500"
                      onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                    >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
                  </div>
                </Field>
              </div>
              <Button
                className="btn-primary mt-4"
                disabled={!pw_data?.old_password
                    || !pw_data?.new_password
                    || !pw_data?.confirm_new_password
                    || pw_data?.new_password !== pw_data?.confirm_new_password
                    || user_data_saving
                }
                onClick={() => this.props.userUpdateData({...pw_data})}
              >
                Change Password
              </Button>
            </div>
          </div>
        </div>
        {/*
        <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white mt-8">
          <h2 className="font-medium mb-5">Connected Marketplaces</h2>
          <table className="w-full border-spacing-5 border-collapse text-sm">
            <thead>
              <tr className="border-b-2 border-slate-200">
                <th className="px-3">SELLER ID</th>
                <th className="px-3">MARKETPLACE</th>
                <th className="px-3">CONNECTION NAME</th>
                <th className="px-3"></th>
              </tr>
            </thead>
            <tbody>
            {connectedMarketplaces.length > 0 ?
            connectedMarketplaces.map(item => (
              <tr key={`connection-${item.seller_id}`} className="border-t border-slate-200">
                <td className="p-3">
                  <Input type="text" defaultValue={item.seller_id} placeholder="" />
                </td>
                <td className="p-3">
                  <Select
                    options={marketplaces_options}
                    defaultValue={() => marketplaces_options.find(m => m.value === item.marketplace)}
                    classNames={{
                      container: () => 'w-96',
                      menu: () => 'w-96',
                    }}
                  />
                </td>
                <td className="p-3">
                  <Input type="text" defaultValue={item.name} placeholder="Ex: My Amazon Store" className="min-w-96" />
                </td>
                <td className="p-3">
                  <Button className="btn-primary">Update</Button>
                </td>
              </tr>
            ))
            :
            <tr>
              <td>
                <p>You don't have Amazon account connected, please connect 2D BoxContent with your Amazon account.</p>
                <Link to="/app/connect" className="btn-primary btn-link inline-block mt-4">Connect Account</Link>
              </td>
            </tr>
            }
            </tbody>
          </table>
        </div>
        */}
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_data_saving: state.User.get("user_data_saving"),
  }),
  {
    userUpdateData,
  }
)(Account);
