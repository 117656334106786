import React, { Component } from 'react'

const users = [
  {id: 1, username: 'john.doe', email: 'john@doe.com', is_active: true, created_date: '09/24/2024'},
  {id: 2, username: 'billy', email: 'billy@gmail.com', is_active: false, created_date: '09/12/2024'}
]

class AdminUsers extends Component {
  render() {
    return (
      <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
        <div className="mb-4">
          <div className="flex gap-6 font-semibold">
            <span>Active Users: 1</span>
            <span>Total Users: {users.length}</span>
          </div>
        </div>
        <table className="table-auto w-full border-spacing-5 border-collapse text-[15px]">
          <thead>
            <tr className="border-b-2 border-slate-200">
              <th className="px-3">USERNAME</th>
              <th className="px-3">EMAIL</th>
              <th className="px-3">SUBSCRIPTION</th>
              <th className="px-3">CREATED DATE</th>
            </tr>
          </thead>
          <tbody>
            {users.map(item => (
            <tr key={`user-${item.id}`} className="border-t py-3 border-slate-200">
              <td className="p-3">{item.username}</td>
              <td className="p-3">{item.email}</td>
              <td className="p-3">
                <div className="uppercase font-semibold">
                  {item.is_active ? 
                  <span className="text-green-700">ACTIVE</span> : 
                  <span className="text-slate-500">INACTIVE</span>
                  }
                </div>
              </td>
              <td className="p-3">{item.created_date}</td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AdminUsers