import { Button, DialogTitle, Input } from "@headlessui/react"
import React, { Component } from 'react'
import Modal from "../../components/Modal"

let timer;

class VerifyCodeModal extends Component {
  state = {
    resend_code_timer: 0,
    phone_sms_code: null
  }

  componentDidMount() {
    this.startTimer()
  }

  startTimer = () => {
    this.setState({ resend_code_timer: 30 })
    timer = setInterval(() => {
      let resend_code_timer = this.state.resend_code_timer
      if(resend_code_timer <= 0) {
        clearInterval(timer)
      } else {
        resend_code_timer--
      }
      this.setState({ resend_code_timer })
    }, 1000)
  }

  render() {
    const { open, onClose, onVerify, onResendCode, registration_working} = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <DialogTitle className="font-semibold text-lg mb-4">Verify Code</DialogTitle>
        <div className="flex gap-4">
          <div>
            <svg width="80" height="90" xmlns="http://www.w3.org/2000/svg">
              <g fillRule="nonzero" fill="#3C404C">
              <path d="M15.79 65a2.966 2.966 0 0 1-1.962-.741 2.812 2.812 0 0 1-.832-3.029l.99-3.003c-3.352-.913-5.699-3.726-5.699-7.126V32.705c0-4.096 3.442-7.429 7.673-7.429h56.367c4.23 0 7.673 3.333 7.673 7.429v18.396c0 4.096-3.441 7.429-7.672 7.429l-41.588.068-13.662 6.109A2.994 2.994 0 0 1 15.79 65Zm-4.503-32.295v18.396c0 2.387 2.042 4.292 4.75 4.431l.413.021c.78.04 1.309.811 1.064 1.553l-1.601 4.856L30.057 55.6l42.271-.07c2.576 0 4.673-1.986 4.673-4.429V32.705c0-2.442-2.097-4.429-4.673-4.429H15.96c-2.577 0-4.673 1.987-4.673 4.429Z"/>
              <path d="m27.154 42.091-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.056 1.14v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.502 1.502 0 0 0-2.04.584 1.502 1.502 0 0 0 .584 2.04l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.625l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.056 1.14a1.501 1.501 0 0 0 1.456-2.625ZM41.947 42.091l-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.057 1.141v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.5 1.5 0 0 0-1.455 2.624l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.625l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.057 1.141a1.501 1.501 0 1 0 1.455-2.627ZM56.739 42.091l-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.056 1.14v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.5 1.5 0 0 0-1.455 2.624l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.624l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.056 1.14a1.498 1.498 0 0 0 2.039-.584 1.5 1.5 0 0 0-.584-2.04ZM72.739 42.091l-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.056 1.14v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.5 1.5 0 0 0-1.455 2.624l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.624l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.056 1.14a1.498 1.498 0 0 0 2.039-.584 1.5 1.5 0 0 0-.584-2.04Z"/>
              <path d="M46.303 89.833H8.689C3.897 89.833 0 85.935 0 81.144V8.689C0 3.898 3.898 0 8.689 0h37.614c4.791 0 8.689 3.898 8.689 8.689v11.144a1.5 1.5 0 1 1-3 0V8.689A5.695 5.695 0 0 0 46.303 3H8.689A5.695 5.695 0 0 0 3 8.689v72.454a5.695 5.695 0 0 0 5.689 5.689h37.614a5.696 5.696 0 0 0 5.689-5.689V62.667a1.5 1.5 0 0 1 3 0v18.477c.001 4.791-3.898 8.689-8.689 8.689Z"/>
              <path d="M36.337 11.521H18.664c-2.847 0-5.164-2.402-5.164-5.355V2.515h3v3.651c0 1.298.971 2.355 2.164 2.355h17.673c1.192 0 2.163-1.057 2.163-2.355V2.515h3v3.651c0 2.953-2.316 5.355-5.163 5.355Z"/>
              </g>
            </svg>
          </div>
          <div>
            <p className="mb-4">We've sent verification code to your mobile phone.<br/> Please enter code you receive.</p>
            <div className="d-flex gap-2 mb-2">
              <Input
                type="text"
                onChange={(e) => this.setState({phone_sms_code: e.target.value})}
              />
              <Button
                className="btn-primary ml-2"
                onClick={(e) => {
                  onVerify(this.state.phone_sms_code);
                  onClose();
                }}
              >Verify</Button>
            </div>
            <div className="mt-8">
              <span className="text-slate-500 text-sm ">Didn't receive the code?</span>
              <Button
                className="btn-tertiary btn-sm ml-2"
                onClick={(e) => {
                  onResendCode()
                  this.startTimer()
                }}
                disabled={
                  registration_working
                  || this.state.resend_code_timer > 0
                }
              >{`${this.state.resend_code_timer === 0 ? 'Resend code' : `Wait (${this.state.resend_code_timer}s)`}`}</Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default VerifyCodeModal
