import React, { Component } from 'react'
import Modal from "./Modal"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Input } from "@headlessui/react"
import { ReactComponent as IconPrint } from '../assets/icons/icon-print.svg'
import { productNoImage } from "../assets/images"
import Barcode from 'react-barcode';
import Select from 'react-select';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment-timezone";

const fnsku_label_options = [
  {
    value: '2.00_x_1.00_thermal_label',
    label: '2.00 x 1.00 Thermal Label',
    width: 2,
    height: 1,
    unit: 'in',
    orientation: 'l',
  },
  {
    value: '2.125_x_1.00_thermal_label',
    label: '2.215 x 1.00 Thermal Label',
    width: 2.215,
    height: 1,
    unit: 'in',
    orientation: 'l',
  },
  {
    value: '2.50_x_1.00_thermal_label',
    label: '2.50 x 1.00 Thermal Label',
    width: 2.5,
    height: 1,
    unit: 'in',
    orientation: 'l',
  },
  {
    value: '2.62_x_1.00_thermal_label',
    label: '2.62 x 1.00 Thermal Label',
    width: 2.62,
    height: 1,
    unit: 'in',
    orientation: 'l',
  },
  {
    value: '2.25_x_1.25_thermal_label',
    label: '2.25 x 1.25 Thermal Label',
    width: 2.25,
    height: 1.25,
    unit: 'in',
    orientation: 'l',
  },
  {
    value: '3.00_x_1.00_thermal_label',
    label: '3.00 x 1.00 Thermal Label',
    width: 3,
    height: 1,
    unit: 'in',
    orientation: 'l',
  },
  {
    value: '3.00_x_2.00_thermal_label',
    label: '3.00 x 2.00 Thermal Label',
    width: 3,
    height: 2,
    unit: 'in',
    orientation: 'l',
  },
  {
    value: '3.50_x_1.12_thermal_label',
    label: '3.50 x 1.12 Thermal Label',
    width: 3.5,
    height: 1.12,
    unit: 'in',
    orientation: 'l',
  },
]

class PrintItemLabelsModal extends Component {
  state = {
    labels_amount: 1,
    fnsku_label_option: null,
    expiration: null,
  }

  onPrintDone = () => {
    const { fnsku_label_option, labels_amount } = this.state;
    const input = document.getElementById("barcode");
    html2canvas(input).then(canvas => {;
      const data = canvas.toDataURL("image/png");
      const pdf = new jsPDF(
        fnsku_label_option.orientation,
        fnsku_label_option.unit,
        [fnsku_label_option.width, fnsku_label_option.height]
      );
      pdf.addImage(data, "PNG", 0, 0, fnsku_label_option.width, fnsku_label_option.height);
      for (let i = 1; i < labels_amount; i++) {
        pdf.addPage();
        pdf.addImage(data, "PNG", 0, 0, fnsku_label_option.width, fnsku_label_option.height);
      }
      pdf.save("labels.pdf");
    });
  }

  render() {
    const { open, onClose, item_data } = this.props;

    return (
      <Modal open={open} onClose={onClose} size="xl">
        <div className="flex gap-4">
          <div className="basis-1/2 text-center border rounded">
            <div
              className={`p-2 mx-auto min-h-[500px] relative bg-no-repeat bg-center`}
              style={{ backgroundImage: `url('${productNoImage}')`}}
            >
              <img
                // we need large image dimension here, recommended 500px in width
                // but fetching image by ASIN has no guarantee it will return the image, so ideally it's needed on db
                src={`https://images.amazon.com/images/P/${item_data.asin1}.01._SCLZZZZZZZ_.jpg`}
                alt=""
                className="relative z-20"
              />
            </div>
          </div>
          <div className="basis-1/2">
            <h6 className="font-semibold mb-6">{item_data.item_name}</h6>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <span className="text-slate-500">SKU</span>
                <span className="block text-lg font-semibold">{item_data.seller_sku}</span>
              </div>
              <div>
                <div>
                  <span className="text-slate-500">Notes</span>
                  <p className="text-orange-600"><em>{item_data.notes}</em></p>
                </div>
                <div className="mt-4">
                  <span className="text-slate-500">Amazon Prep Instruction</span>
                  <p>-</p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <span className="text-slate-500 block mb-1">Expiration Date <small>(only if applicable)</small></span>
              <DatePicker
                selected={item_data.expiration_date ? item_data.expiration_date : null}
                onChange={selected => this.setState({expiration: selected})}
              />
            </div>
            {/* show quantity remaining if user working on active shipment,
            later this modal can be reused on other places like my inventory, but qty remaining isn't relevant there */}
            {(item_data.qty !== undefined && item_data.done_qty !== undefined) ?
            <div className="mt-4">
              <span className="text-slate-500">Qty Remaining</span>
              <span className="block py-2">{item_data.qty - item_data.done_qty}</span>
            </div>
            : null
            }
            <div className="mt-4">
              <span className="text-slate-500">FNSKU</span>
              <div
                id="barcode"
                className="w-[305px] border border-slate-500 rounded p-2"
              >
                <Barcode
                  format="CODE128A"
                  value={item_data.fnsku}
                  height={50}
                  margin={0}
                />
                <div className="text-sm mt-1 leading-tight">{item_data.item_name.substring(0, 60)}...</div>
                <div className="flex justify-between items-center gap-2 text-sm">
                  <div>{item_data.item_condition_human}</div>
                  <div>{this.state.expiration ? moment(this.state.expiration).format("L") : null}</div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <span className="text-slate-500">Label Size</span>
                <Select
                  options={fnsku_label_options}
                  onChange={(e) => this.setState({fnsku_label_option: e})}
                  value={this.state.fnsku_label_option}
                />
              </div>
              <div>
                <span className="text-slate-500">Item labels to print</span>
                <Input
                  type="number"
                  min={1}
                  className="block"
                  value={this.state.labels_amount}
                  onChange={e => this.setState({ labels_amount: parseInt(e.target.value) })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 mt-4">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <Button
            className="btn-primary"
            disabled={!this.state.labels_amount || !this.state.fnsku_label_option?.value}
            onClick={() => {
              this.onPrintDone()
              //onClose()
            }}
          >
            <IconPrint className="size-4 inline mr-2" />
            <span>Print Item Labels</span>
          </Button>
        </div>
      </Modal>
    )
  }
}

export default PrintItemLabelsModal
