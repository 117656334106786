import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import React, { Component } from 'react'
import SettingAddress from "./SettingAddress"
import SettingGeneral from "./SettingGeneral"

class Settings extends Component {
  render() {
    return (
      <div>
        <h1 className="font-semibold text-xl uppercase mb-5">Settings</h1>
        <TabGroup>
          <TabList>
            <Tab>General</Tab>
            <Tab>My Address</Tab>
          </TabList>
          <TabPanels className="mt-4">
            <TabPanel>
              <SettingGeneral />
            </TabPanel>
            <TabPanel>
              <SettingAddress />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    )
  }
}

export default Settings