import { Field } from "@headlessui/react"
import React, { Component } from 'react'
import Select from 'react-select'
import { digitСonversion } from "../../helpers/utils"

class SelectPackingOptions extends Component {
  generateSelectOption = () => {
    const { packing_options_data } = this.props;
    let options = []
    packing_options_data?.packingOptions?.forEach(opt => {
      let feeEl = []
      let totalFee = 0
      opt.fees.forEach((fee, idx) => {
        totalFee += fee.currency.amount
        feeEl.push(
          <span
            key={`fee-${opt.packingOptionId}-${idx}`}
            className="font-medium mr-1"
          >
            {digitСonversion(fee.currency.amount, 'currency', fee.currency.code)}
          </span>
        )
      })
      const label = (
        <div>
          {feeEl} <span className="text-slate-500">({opt.packingGroups.length} package{opt.packingGroups.length > 1 && 's'})</span>
          {/* Recommended indicator with assumption of how we define option is recommended */}
          <span className="text-red-700 ml-1">{opt.status}</span>
          {totalFee === 0 && <span className="text-green-700 ml-1">Recommended</span> }
        </div>
      )
      options.push({
        value: opt.packingOptionId,
        label: label,
        total_fee: totalFee,
        data: opt,
      })
    })
    return options
  }

  render() {
    const {
      classNames,
      onChange,
      disabled,
      select_packing_option,
    } = this.props

    const selectOptions = this.generateSelectOption()
    const sortedOptions = selectOptions.sort((a, b) => {return a.total_fee - b.total_fee})

    return (
      <Field className={`field ${classNames}`}>
        <Select
          placeholder="Select inbound packing service"
          options={sortedOptions}
          isSearchable={false}
          onChange={(select) => onChange(select)}
          isDisabled={disabled}
          value={sortedOptions.find(item => item.value === select_packing_option)}
        />
      </Field>
    )
  }
}

export default SelectPackingOptions;
