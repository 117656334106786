import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Field, Input, Label, Switch } from "@headlessui/react"
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg'
import AddressPopover from "../../components/AddressPopover"
import AddressFormModal from "../../components/AddressFormModal"
import RadioPackingTypes, { packing_types } from "../../components/RadioPackingTypes"
import Select from 'react-select'
import RadioShipmentTypes, { shipment_types } from "../../components/RadioShipmentType"
import AddItemsModal from "./AddItemsModal"
// import FeedbackMessage from "../../components/FeedbackMessage"
import ShipmentPlanner from "./ShipmentPlanner"
import userActions from "../../redux/user/actions";
import shipmentActions from "../../redux/shipment/actions";
import { withRouter } from "react-router";
import SelectPrepCategory from "../../components/SelectPrepCategory";
import Modal from "../../components/Modal";
//import SelectPrepType from "../../components/SelectPrepType";

const { userGetAddressAll } = userActions;
const {
  shipmentCreate,
  shipmentUpdate,
  shipmentPlanCreate,
  shipmentBoxDelete,
} = shipmentActions;

const who_labels_options = [
  {value: 'SELLER', label: 'Seller'},
  {value: 'AMAZON', label: 'Amazon'},
  {value: 'NONE', label: 'None'},
]

const who_preps_options = [
  {value: 'SELLER', label: 'Seller'},
  {value: 'AMAZON', label: 'Amazon'},
  {value: 'NONE', label: 'None'},
]

class CreateEditShipmentDraft extends Component {
  state = {
    openAddressModal: false,
    who_labels: who_labels_options[2],
    who_preps: who_preps_options[2],
    showAddItemsModal: false,
    shipment: {},
    changePrepCategoryItemId: null,
    changePrepTypeItemId: null,
  }

  componentDidMount() {
      this.props.userGetAddressAll(
        {},
        {
          success: [
            {
              function: this.setDefaultAddress,
              params: [],
            }
          ],
        }
      );
    if(this.props.mode === "create"){
      this.setShipmentData({
        "item_data": this.props?.selectedProducts?.map(item => {
          item.quantity = 0;
          item.labeling_option = "SELLER";
          item.prep_option = "NONE";
          item.asin = item.asin1;
          return item;
        }),
        "shipment_type": "LTL",
        "packing_type": "CASE",
        "amazon_partnered": false,
      })
    }
  }

  componentDidUpdate = (pp) => {
    if(!pp.data?.item_data
      && this.props.mode === "edit"
      && this.state.shipment?.item_data === undefined
      && this.props.data?.item_data
    ){
      this.setShipmentData({
        "item_data": this.props.data.item_data.map(item => {
          item.labeling_option = item.labeling_option_human;
          item.prep_option = item.prep_option_human;
          item.asin1 = item.asin;
          return item;
        }),
      })
    }
  }

  setShipmentData = (data) => {
    var shipment = {...this.state.shipment}
    this.setState({shipment: {...shipment, ...data}});
  }

  setShipmentItemData = (sku, key, value) => {
    var shipment = {...this.state.shipment}
    shipment.item_data = shipment?.item_data?.map(item => {
      if(item.seller_sku === sku){
        item[key] = value;
      }
      return item;
    });
    this.setState({shipment: shipment});
  }

  setDefaultAddress = (res) => {
    this.setShipmentData({
      "address": res?.data?.data?.find(item => item.default === true)
    })
  }

  addItems = (items) => {
    const { shipment } = this.state;
    const existing_skus = shipment?.item_data?.map(item => {return item.seller_sku});
    let differentItemsOnly = items.filter(item => !existing_skus.includes(item.seller_sku));
    differentItemsOnly = differentItemsOnly.map(item => {
      item.quantity = 0;
      item.labeling_option = "SELLER";
      item.prep_option = "NONE";
      item.asin = item.asin1;
      return item;
    })
    this.setShipmentData({"item_data": shipment?.item_data?.concat(differentItemsOnly)});
  }

  removeItem = (sku) => {
    var shipment = {...this.state.shipment};
    shipment.item_data = shipment?.item_data?.filter(item => item.seller_sku !== sku)
    this.setState({shipment: shipment});
  }

  validateShipmentData = () => {
    const { shipment } = this.state;
    var valid = true;
    if(this.props.mode !== "edit"){
      if(!shipment?.address
        || !shipment?.shipment_type
        || !shipment?.packing_type
        || !shipment?.name
      ){ return false };
    }
    shipment?.item_data?.forEach(item => {
      if(!item?.quantity || !item?.labeling_option || !item?.prep_option){
        valid = false;
      }
    })
    return valid;
  }

  createShipmentDraft = () => {
    var { shipment } = this.state;
    this.props.shipmentCreate(shipment);
  }

  shipmentUpdate = () => {
    var shipment = {...this.state.shipment};
    shipment.id = this.props.shipment_id;
    this.props.shipmentUpdate(shipment);
  }

  render() {
    const {
      addresses,
      shipment_working,
      mode,
      data,
    } = this.props;

    const {
      openAddressModal,
      showAddItemsModal,
      shipment,
    } = this.state

    console.log("SHIPMENT:", shipment)

    return (
      <div>
        <div className="flex items-center justify-between gap-4 mb-5">
          {mode === "create" ? <h1 className="font-semibold text-xl uppercase">Create New Shipment Draft</h1>
          :
          <div>
            <div className="flex gap-4">
              <span className="text-slate-500">View Shipment Draft</span>
              <span className="text-green-700" role="button" onClick={() => this.props.history.goBack()}>&larr; Back</span>
            </div>
            <h1 className="font-semibold text-xl uppercase">{data?.name}</h1>
          </div>
          }
          <div className="flex items-center gap-2">
            <Button className="btn-secondary flex items-center gap-1">
              <IconPrint /> <span>Print All Labels</span>
            </Button>
            <Button className="btn-secondary flex items-center gap-1">
              <IconPrint /> <span>Print Custom Labels</span>
            </Button>
          </div>
        </div>
        {/* <FeedbackMessage className="mb-8" color="red" message="This is an example of error message that need to be read by users thoroughly" /> */}
        <div className="flex gap-4">
          <div className="basis-2/3 px-3 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          <div className="flex justify-between items-center mb-8">
            <div className="flex items-center gap-2">
            </div>
            <Button className="btn-tertiary flex items-center gap-1" onClick={() => this.setState({ showAddItemsModal: true })}>
              + <span>Add Items</span>
            </Button>
            {showAddItemsModal &&
            <AddItemsModal
              open={true}
              onClose={() => this.setState({ showAddItemsModal: false })}
              onAddItems={items => this.addItems(items)}
              alreadyAddedItems={shipment?.item_data}
            />
            }
          </div>
            <table className="table-auto w-full border-spacing-5 border-collapse">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="px-3">PRODUCT</th>
                  <th className="px-3">QTY</th>
                  {mode !== "create" &&
                  <th className="px-3">PREPPING</th>
                  }
                  <th className="px-3">WHO LABELS?</th>
                  <th className="px-3">WHO PREPS?</th>
                  <th className="px-3"></th>
                </tr>
              </thead>
              {shipment?.item_data?.length > 0 ?
              <tbody>
                {shipment?.item_data?.map(item => (
                <tr key={`selected-${item.id}-${item.seller_sku}`} className="border-t py-3 border-slate-200">
                  <td className="p-3 w-96">
                    <div className="flex gap-2">
                      <img
                        src={item.img_url
                          ? item.img_url
                          : `https://www.amazon.com/images/P/${item.asin1}.01._THUMBZZZ_.jpg`
                        }
                        alt=""
                        className="w-9"
                      />
                      <div>
                        <span className="line-clamp-2 max-w-96 text-sm">{item.item_name}</span>
                        <small>SKU: {item.seller_sku} / {item.condition ? item.condition : '-'} /
                          ASIN: <a href={`https://www.amazon.com/dp/${item.asin1}`} target="_blank" rel="noreferrer">{item.asin1}</a>
                        </small>
                      </div>
                    </div>
                  </td>
                  <td className="p-3 w-24">
                    <Input
                      type="number"
                      min={0}
                      className="max-w-20"
                      defaultValue={item?.quantity || 0}
                      value={item?.quantity ? item?.quantity : 0}
                      onChange={(e) => this.setShipmentItemData(
                        item.seller_sku,
                        "quantity",
                        e.target.value
                      )}
                    />
                  </td>
                  {mode !== "create" &&
                  <td className="p-3 text-sm">
                    <div className="whitespace-nowrap">
                      <span>Category: {shipment?.item_data?.find(i => i.seller_sku === item.seller_sku).prep_category_option_human}
                      </span>
                      <IconEdit className="size-3 ml-2 inline text-green-700" role="button"
                        onClick={() => this.setState({ changePrepCategoryItemId: item.id })}
                      />
                      {this.state.changePrepCategoryItemId &&
                      <Modal open={true} onClose={() => this.setState({ changePrepCategoryItemId: null })}>
                        <Field className="field">
                          <Label>Select prep category</Label>
                          <SelectPrepCategory
                            onChange={selected => {
                              this.setShipmentItemData(
                                item.seller_sku,
                                "prep_category_option",
                                selected.value)
                              this.setShipmentItemData(
                                item.seller_sku,
                                "prep_category_option_human",
                                selected.value)
                              this.setState({ changePrepCategoryItemId: null })
                            }}
                          />
                        </Field>
                      </Modal>
                      }
                    </div>
                    {/*
                    <div>
                      <span>Type: ITEM_SUFFOSTK, ITEM_BUBBLEWRAP</span>
                      <IconEdit className="size-3 ml-2 inline text-green-700" role="button"
                        onClick={() => this.setState({ changePrepTypeItemId: item.id })}
                      />
                      {this.state.changePrepTypeItemId &&
                      <Modal open={true} onClose={() => this.setState({ changePrepTypeItemId: null })}>
                        <Field className="field">
                          <Label>Select prep type</Label>
                          <SelectPrepType
                            onChange={selected => {
                              console.log(selected)
                            }}
                          />
                        </Field>
                        <div className="flex justify-end mt-4">
                          <Button
                            className="btn-primary"
                            onClick={() => this.setState({ changePrepTypeItemId: null })}
                          >Save</Button>
                        </div>
                      </Modal>
                      }
                    </div>
                    */}
                  </td>
                  }
                  <td className="p-3">
                    <Select
                      options={who_labels_options}
                      defaultValue={who_labels_options?.find(option => option.value === item.labeling_option)}
                      classNames={{
                        menu: () => 'min-w-40',
                      }}
                      onChange={selected => this.setShipmentItemData(
                        item.seller_sku,
                        "labeling_option",
                        selected.value,
                      )}
                    />
                  </td>
                  <td className="p-3">
                    <Select
                      options={who_preps_options}
                      defaultValue={who_preps_options?.find(option => option.value === item.prep_option)}
                      classNames={{
                        menu: () => 'min-w-40',
                      }}
                      onChange={selected => this.setShipmentItemData(
                        item.seller_sku,
                        "prep_option",
                        selected.value,
                      )}
                    />
                  </td>
                  <td className="p-3">
                    <div className="flex gap-2 items-center">
                      <IconPrint className="size-4 text-slate-500" role="button" />
                      <span
                        className="text-red-700"
                        role="button"
                        onClick={() => this.removeItem(item.seller_sku)}
                      >&times;</span>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
              :
              <tfoot>
                <tr>
                  <td className="p-3 text-center text-slate-500" colSpan={5}>You don't have items for shipment, please add the items.</td>
                </tr>
              </tfoot>
              }
            </table>
          </div>
          <div className="basis-1/3">
            {mode === 'edit' &&
            <div className="px-3 py-4 border border-slate-200/50 rounded-lg shadow bg-white mb-4">
              <ShipmentPlanner
                shipment={this.props.shipment}
                items={shipment?.item_data || []}
                shipmentPlanCreate={this.props.shipmentPlanCreate}
                shipmentBoxDelete={this.props.shipmentBoxDelete}
                shipment_working={shipment_working}
              />
            </div>
            }
            <div className="px-3 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
              <Field className="field">
                <Label>Shipment Draft Name</Label>
                <Input
                  type="text"
                  value={shipment?.name
                    ? shipment?.name
                    : shipment?.name === undefined
                      ? data?.name
                      : shipment?.name
                  }
                  onChange={(e) => this.setShipmentData({"name": e.target.value})}
                />
              </Field>
              <Field className="field mt-8">
                <Label>Ship From</Label>
                {addresses ?
                  <div>
                  {shipment?.address === undefined
                    ? data?.address
                      ? <p className="mb-2">
                          {data?.address?.address_line_1}, {data?.address?.address_line_2}<br/>
                          {data?.address?.city}, {data?.address?.state_or_province_code}, {data?.address?.postal_code}, {data?.address?.country_code}
                        </p>
                      : <p className="text-slate-500">Undefined ship-from address.</p>
                    : <p className="mb-2">
                        {shipment?.address?.address_line_1}, {shipment?.address?.address_line_2}<br/>
                        {shipment?.address?.city}, {shipment?.address?.state_or_province_code}, {shipment?.address?.postal_code}, {shipment?.address?.country_code}
                      </p>
                  }
                  <AddressPopover
                    buttonText="Change Address"
                    anchor="bottom end"
                    selectedAddress={shipment?.address ? shipment?.address : null}
                    select={selected => this.setShipmentData({"address": selected})}
                    addNewAddress={() => this.setState({ openAddressModal: true })}
                  />
                </div>
                :
                <>
                  <p className="text-slate-500">You don't have any ship-from address.</p>
                  <span className="text-green-700" role="button" onClick={() => this.setState({ openAddressModal: true })}>Add new address</span>
                </>
                }
                <AddressFormModal
                  open={openAddressModal}
                  mode="add-address"
                  onClose={() => this.setState({ openAddressModal: false })}
                />
              </Field>
              <Field className="field mt-8">
                <Label>Packing Type</Label>
                <RadioPackingTypes
                  value={data?.packing_type_human
                    ? shipment?.packing_type === undefined
                      ? packing_types.find(item => item.value === data?.packing_type_human)
                      : packing_types.find(item => item.value === shipment?.packing_type)
                    : packing_types.find(item => item.value === shipment?.packing_type)
                  }
                  onChange={selected => {
                    this.setShipmentData({"packing_type": selected?.value})}
                  }
                />
              </Field>
              <Field className="field mt-8">
                <Label>Shipment Type</Label>
                <RadioShipmentTypes
                  value={data?.shipment_type_human
                    ? shipment?.shipment_type === undefined
                      ? shipment_types.find(item => item.value === data?.shipment_type_human)
                      : shipment_types.find(item => item.value === shipment?.shipment_type)
                    : shipment_types.find(item => item.value === shipment?.shipment_type)
                  }
                  onChange={selected => {
                    this.setShipmentData({"shipment_type": selected?.value})}
                  }
                />
              </Field>
              <Field className="field mt-8">
                <Label className="flex items-center gap-2">
                  <Switch
                    checked={shipment?.amazon_partnered === undefined
                      ? data?.amazon_partenered
                        ? true
                        : false
                      : shipment?.amazon_partnered
                        ? true
                        : false
                    }
                    onChange={() => this.setShipmentData({"amazon_partnered": !shipment?.amazon_partnered})}
                  /> <span>Amazon Partnered</span>
                </Label>
              </Field>
            </div>
          </div>
        </div>
        {this.props.mode === 'create' ?
        <div className="flex justify-between mt-4">
          <Button
            className="btn-primary"
            onClick={this.createShipmentDraft}
            disabled={shipment_working || !this.validateShipmentData()}
          >Create Shipment Draft</Button>
          <Button className="btn-tertiary" onClick={this.props.cancel}>Cancel</Button>
        </div>
        :
          <Button
            className="btn-secondary mt-4"
            onClick={() => this.shipmentUpdate()}
            disabled={shipment_working || !this.validateShipmentData()}
          >Save Draft Changes</Button>
        }
      </div>
    )
  }
}

export default withRouter(connect(
  state => ({
    userData: state.User.get("userData"),
    addresses: state.User.get("addresses"),
    shipment_working: state.Shipment.get("shipment_working"),
    shipment: state.Shipment.get("shipment"),
  }),
  {
    userGetAddressAll,
    shipmentCreate,
    shipmentUpdate,
    shipmentPlanCreate,
    shipmentBoxDelete,
  }
)(CreateEditShipmentDraft));
