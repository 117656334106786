import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* shipmentCreate() {
  yield commonSaga(
    actions.SHIPMENT_CREATE,
    apiCalls.post,
    apiEndpoints.shipments.shipment,
    {
      ...default_config,
      ...{
        success_show: true,
        redirect_on_success: "/app/shipment/drafts",
      }
    }
  );
}

export function* shipmentGetAll() {
  yield commonSaga(
    actions.SHIPMENT_GET_ALL,
    apiCalls.get,
    apiEndpoints.shipments.all,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* shipmentUpdate() {
  yield commonSaga(
    actions.SHIPMENT_UPDATE,
    apiCalls.put,
    apiEndpoints.shipments.shipment,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* shipmentGet() {
  yield commonSaga(
    actions.SHIPMENT_GET,
    apiCalls.get,
    apiEndpoints.shipments.shipment,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* shipmentPlanCreate() {
  yield commonSaga(
    actions.SHIPMENT_PLAN_CREATE,
    apiCalls.post,
    apiEndpoints.shipments.shipment_plan,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* shipmentGetActiveAll() {
  yield commonSaga(
    actions.SHIPMENT_GET_ACTIVE_ALL,
    apiCalls.get,
    apiEndpoints.shipments.active_all,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* shipmentGetActive() {
  yield commonSaga(
    actions.SHIPMENT_GET_ACTIVE,
    apiCalls.get,
    apiEndpoints.shipments.active,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* shipmentActiveBoxCreate() {
  yield commonSaga(
    actions.SHIPMENT_ACTIVE_BOX_CREATE,
    apiCalls.post,
    apiEndpoints.shipments.active_box,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* shipmentActiveBoxDelete() {
  yield commonSaga(
    actions.SHIPMENT_ACTIVE_BOX_DELETE,
    apiCalls.delete,
    apiEndpoints.shipments.active_box,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* shipmentActiveSetPackageInfo() {
  yield commonSaga(
    actions.SHIPMENT_ACTIVE_SET_PACKAGE_INFO,
    apiCalls.post,
    apiEndpoints.shipments.active,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* shipmentActiveGetDeliveryWindow() {
  yield commonSaga(
    actions.SHIPMENT_ACTIVE_GET_DELIVERY_WINDOW,
    apiCalls.post,
    apiEndpoints.shipments.active,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* shipmentActiveConfirmTransportationOptions() {
  yield commonSaga(
    actions.SHIPMENT_ACTIVE_CONFIRM_TRANSPORTATION_OPTIONS,
    apiCalls.post,
    apiEndpoints.shipments.active,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* shipmentCancelInboundPlan() {
  yield commonSaga(
    actions.SHIPMENT_CANCEL_INBOUND_PLAN,
    apiCalls.delete,
    apiEndpoints.shipments.shipment_plan,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* shipmentActiveGetLabels() {
  yield commonSaga(
    actions.SHIPMENT_ACTIVE_GET_LABELS,
    apiCalls.post,
    apiEndpoints.shipments.active,
    {
      ...default_config,
      ...{
        success_show: true,
        open_in_tab: true,
      }
    }
  );
}

export function* shipmentBoxCreate() {
  yield commonSaga(
    actions.SHIPMENT_BOX_CREATE,
    apiCalls.post,
    apiEndpoints.shipments.shipment_box,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* shipmentBoxDelete() {
  yield commonSaga(
    actions.SHIPMENT_BOX_DELETE,
    apiCalls.delete,
    apiEndpoints.shipments.shipment_box,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}


export default function* shipmentSaga() {
	yield all([
    fork(shipmentCreate),
    fork(shipmentGetAll),
    fork(shipmentUpdate),
    fork(shipmentGet),
    fork(shipmentPlanCreate),
    fork(shipmentGetActiveAll),
    fork(shipmentGetActive),
    fork(shipmentActiveBoxCreate),
    fork(shipmentActiveBoxDelete),
    fork(shipmentActiveSetPackageInfo),
    fork(shipmentActiveGetDeliveryWindow),
    fork(shipmentActiveConfirmTransportationOptions),
    fork(shipmentCancelInboundPlan),
    fork(shipmentActiveGetLabels),
    fork(shipmentBoxCreate),
    fork(shipmentBoxDelete),
	]);
}
