import React, { Component } from 'react';
import { flagCA, flagGB, flagUS } from "../../assets/images";

const flagMap = {
  'US' : flagUS,
  'CA' : flagCA,
  'GB' : flagGB
}

class RelatedShipmentsTable extends Component {

  shipmentTotalQty = (shipment) => {
    return shipment?.items?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);
  }

  shipmentInBoxesQty = (shipment) => {
    return shipment?.boxes?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);
  }

  displayProgress = (shipment) => {
    const shipment_total_qty = this.shipmentTotalQty(shipment);
    const shipment_in_boxes_qty = this.shipmentInBoxesQty(shipment);

    return (
      <React.Fragment>
        <div className="px-1 h-2 relative bg-slate-700 text-xs text-white rounded overflow-clip text-center">
          <div
            className="bg-green-700 absolute left-0"
            style={{ width: `${shipment_total_qty ? shipment_in_boxes_qty/shipment_total_qty*100 : 0}%` }}
          >&nbsp;</div>
        </div>
        <div className="z-10 relative text-slate-500 text-center mt-1">{shipment_in_boxes_qty}/{shipment_total_qty}</div>
      </React.Fragment>
    )
  }

  render() {
    const { shipments } = this.props;

    return (
      <table className="table-auto w-full border-spacing-5 border-collapse text-[15px]">
        <thead>
          <tr className="border-b-2 border-slate-200">
            <th className="px-3">SHIPMENT ID</th>
            <th className="px-3">DESTINATION</th>
            <th className="px-3">PROGRESS</th>
          </tr>
        </thead>
        <tbody>
          {shipments.map(item => (
          <tr key={`shipment-${item.id}`} className="border-t py-3 border-slate-200">
            <td className="p-3">
              <a href={`/app/shipment/active/view/${item.id}`}>{item.shipment_confirmation_id}</a>
            </td>
            <td className="p-3">
              <div className="flex">
                <img src={flagMap[item.destination_address?.countryCode]} alt="" className="w-4 mr-2" />
                <span className="leading-none">{item.warehouse_id}<br/>{`${item.destination_address?.city}, ${item.destination_address?.stateOrProvinceCode}`}</span>
              </div>
            </td>
            <td className="p-3">
              {this.displayProgress(item)}
            </td>
          </tr>
          ))}
        </tbody>
      </table>
    )
  }
}

export default RelatedShipmentsTable;
