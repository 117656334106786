import { Field } from "@headlessui/react"
import React, { Component } from 'react'
import Select from 'react-select'
import { digitСonversion } from "../../helpers/utils"

class SelectPlacementOptions extends Component {
  generateSelectOption = () => {
    const { placement_options_data } = this.props;
    let options = []
    placement_options_data?.placementOptions?.forEach(opt => {
      let feeEl = []
      let totalFee = 0
      opt.fees.forEach((fee, idx) => {
        totalFee += fee.value.amount
        feeEl.push(
          <span key={`fee-${opt.placementOptionId}-${idx}`} className="font-medium mr-1">
            {digitСonversion(fee.value.amount, 'currency', fee.value.code)}
          </span>
        )
      })
      const label = (
        <div>
          {feeEl} <span className="text-slate-500">({opt.shipmentIds.length} shipment{opt.shipmentIds.length > 1 && 's'})</span>
          {/* Recommended indicator with assumption of how we define option is recommended */}
          {totalFee === 0 && <span className="text-green-700 ml-1">Recommended</span> }
        </div>
      )
      options.push({
        value: opt.placementOptionId,
        label: label,
        total_fee: totalFee,
        data: opt,
      })
    })
    return options
  }

  render() {
    const {
      classNames,
      onChange,
      disabled,
      select_placement_option,
    } = this.props

    const selectOptions = this.generateSelectOption()
    const sortedOptions = selectOptions.sort((a, b) => {return a.total_fee - b.total_fee})

    return (
      <Field className={`field ${classNames}`}>
        <Select
          placeholder="Select inbound placement service"
          options={sortedOptions}
          isSearchable={false}
          onChange={(select) => onChange(select)}
          isDisabled={disabled}
          value={sortedOptions.find(item => item.value === select_placement_option)}
        />
      </Field>
    )
  }
}

export default SelectPlacementOptions
