import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import RegisterForm from "./RegisterForm";
import { appConfig } from "../../config/app";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// can't use react-stripe-elements, can't be installed on current react version, it's moved to new repo @stripe/react-stripe-js
// need to load Stripe UIs first for styling
// https://www.npmjs.com/package/react-stripe-elements
const pi_id = 'pi_123'

class Register extends Component {
  state = {
    stripePromise: null,
  }

  componentDidMount = () => {
    loadStripe(appConfig.stripeApiKey)
      .then(value => {
        this.setState({ stripePromise: value});
      });
  }

  render() {
    return (
      <div className="container mx-auto flex flex-col h-screen items-center justify-center gap-8">
        <div className="font-bold text-lg">
          2D BoxContent
        </div>
        <div className="px-8 py-8 rounded-md shadow-lg flex flex-col gap-3 w-[40rem] bg-white justify-center">
          <h2 className="font-semibold text-xl mb-3">Create an account</h2>
          <Elements
            stripe={this.state.stripePromise}
            options={{ clientSecret: `${pi_id}_secret_${appConfig.stripeApiKey}` }}
          >
            <ElementsConsumer>
            {({ stripe, elements }) => (
              <RegisterForm
                stripe={stripe}
                elements={elements}
              />
            )}
            </ElementsConsumer>
          </Elements>
          <div className="text-sm text-center mt-2">
            <p><span className="text-slate-500">Already have an account?</span> <Link to="/login">Login</Link></p>
          </div>
        </div>
        <ToastContainer
          hideProgressBar={true}
        />
      </div>
    )
  }
}

export default Register
