import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Input } from "@headlessui/react"
import { ReactComponent as IconCloudSync } from '../../assets/icons/icon-cloud-sync.svg'
import { ReactComponent as IconShipping } from '../../assets/icons/icon-shipping.svg'
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { ReactComponent as IconBox } from '../../assets/icons/icon-box.svg'
import { ReactComponent as IconBoxes } from '../../assets/icons/icon-boxes.svg'
import PrintItemLabelsModal from "../../components/PrintItemLabelsModal"
import BoxArrangementModal from "./BoxArrangementModal"
//import { productNoImage } from "../../assets/images"
import MultiSKUBoxModal from "./MultiSKUBoxModal"
import ViewMultiSKUBoxModal from "./ViewMultiSKUBoxModal"
import LoadingDialog from "../../components/LoadingDialog"
import ConfirmDialog from "../../components/ConfirmDialog"
//import CompleteShipmentSPDModal from "./CompleteShipmentSPDModal"
import BoxArrangementsTable from "./BoxArrangementsTable"
import ViewAllBoxesModal from "./ViewAllBoxesModal"
//import CompleteShipmentLTLModal from "./CompleteShipmentLTLModal"
//import PrintPalletLabelsModal from "./PrintPalletLabelsModal"
//import PrintCustomLabelsModal from "./PrintCustomLabelsModal"
import shipmentActions from "../../redux/shipment/actions";
import CompleteShipment from "./CompleteShipmentModal";
import GetLabelsModal from "./GetLabelsModal";
import RelatedShipmentsTable from "./RelatedShipmentsTable";
//import moment from "moment-timezone";

const {
  shipmentGetActive,
  shipmentActiveBoxDelete,
  shipmentActiveSetPackageInfo,
  shipmentActiveGetLabels,
} = shipmentActions;

const data = {
  shipment_id: 'FBA1883VSKXJ', shipment_name: 'Healthcare Shipment', destination: 'ABE8', status: 'active',
  total_qty: 350, total_done_qty: 0, shipment_type: 'SPD', amazonPartnered: true,
  items: [
    {id: 1, asin1: 'B005PLQIQ4', image_url: 'https://m.media-amazon.com/images/I/51DOyE1fx2L._SL200_.jpg' , item_name: 'Crest + Scope Complete Whitening Toothpaste, Minty Fresh, 5.4 Oz (Pack of 3)',
      seller_sku: 'ABC-12345-00', fnsku: 'X003QPTJBXTB', condition: 'NEW', qty: 100, done_qty: 0, is_item_done: false, notes: 'seal the box'},
    {id: 2, asin1: 'B0B9RQJFDV', image_url: '' , item_name: `Sonic Electric Toothbrush for Adults, USB Rechargeable Sonic Toothbrush with 8 Brush Heads, Smart Timer,
      5 Modes, 2-hour Fast Charge Last 30 Days, Pink', sku: 'ABC-12345-00`, seller_sku: 'ABC-12345-01' , fnsku: 'X003QPTJABCDE', condition: 'NEW', qty: 50, done_qty: 0, is_item_done: false, notes: ''},
    {id: 3, asin1: 'B0BSRCQR2S1', image_url: 'https://m.media-amazon.com/images/I/61TGFSr0TeL._AC_SX679_.jpg' , item_name: 'Boka Fluoride Free Toothpaste- Nano Hydroxyapatite, Remineralizing, Sensitive Teeth, Whitening- Dentist Recommended for Adult, Kids Oral Care- Watermelon Mint Flavor, 4oz 1Pk - US Manufactured',
      seller_sku: 'ABC-12345-10', fnsku: 'X003QPTJBXAB', condition: 'NEW', qty: 200, done_qty: 0, is_item_done: false, notes: 'Seal the top and use bubble wrap.'},
  ],
  boxes: []
}

class ViewActiveShipment extends Component {
  state = {
    sync_working: false,
    shipment_data: data,
    printItemId: null,
    arrangeBoxId: null,
    activeRowIdx: 0,
    showMultiSKUBoxModal: false,
    viewMultiSKUBoxId: null,
    showConfirmMissingUnitsDialog: false,
    confirm_missing_units_working: false,
    showCompleteShipmentModal: false,
    viewAllBoxes: false,
    showPrintPalletLabelsModal: false,
    showPrintCustomLabelsModal: false,
    showGetLabelsModal: false
  }

  componentDidMount = () => {
    this.props.shipmentGetActive({shipment_id: this.props.match.params.id})
  }

  syncShipment = () => {
    this.setState({ sync_working: true })
    setTimeout(() => this.setState({ sync_working: false }), 2000)
  }

  calculateAllItemsDone = () => {
    const shipment_data = this.state.shipment_data
    let all_items_done_qty = 0
    shipment_data.items.forEach(item => {
      if(item?.is_item_done) {
        all_items_done_qty = all_items_done_qty + item.qty
      }
    })
    return all_items_done_qty
  }

  isItemDone = (item, boxes) => {
    let item_arranged_qty = this.getArrangedQty(item, boxes)
    return (item.done_qty === item.qty) && (item_arranged_qty === item.qty)
  }

  getArrangedQty = (item, boxes) => {
    let item_arranged_qty = 0
    boxes.forEach(box => {
      if(box.isMultiSKU) {
        const itemIdx = box.items.findIndex(it => it.id === item.id)
        if(itemIdx > -1) {
          item_arranged_qty = item_arranged_qty + box.items[itemIdx].qty_at_multiskubox
        }
      } else {
        if(box?.items[0].id === item.id) {
          item_arranged_qty = item_arranged_qty + box.units_per_box
        }
      }
    })
    return item_arranged_qty
  }

  onArrangementDone = (boxArrangements) => {
    /*
    let shipment_data = this.state.shipment_data
    let lastBoxNumber = shipment_data.boxes.length
    let item_data = null
    // translate array of arrangements into per box data
    boxArrangements.forEach(boxArrangement => {
      item_data = boxArrangement.item_data
      for (let i = 0; i < boxArrangement.number_of_box; i++) {
        lastBoxNumber++
        shipment_data.boxes.push({
          id: boxArrangement.id+i,
          boxNumber: lastBoxNumber,
          units_per_box: boxArrangement.units_per_box,
          dimension: boxArrangement.dimension,
          weight: boxArrangement.weight,
          expiration_date: boxArrangement.expiration_date,
          item_data: item_data,
          isMultiSKU: false
        })
      }
    })

    const is_item_done = this.isItemDone(item_data, shipment_data.boxes)
    if(is_item_done) {
      const itemIdx = shipment_data.items.findIndex(item => item.id === item_data.id)
      shipment_data.items[itemIdx].is_item_done = is_item_done
      this.moveItemToBottom(itemIdx)
    }

    this.setState({ shipment_data })
    */
  }

  multiSKUBoxDone = (box) => {
    let shipment_data = this.state.shipment_data
    let lastBoxNumber = shipment_data.boxes.length
    const total_items_qty = box?.items?.reduce((sum, item) => sum + item.qty_at_multiskubox, 0)
    shipment_data.boxes.push({
      id: crypto.randomUUID(),
      boxNumber: lastBoxNumber + 1,
      units_per_box: total_items_qty,
      dimension: box.dimension,
      weight: box.weight,
      items: box.items,
      isMultiSKU: true
    })

    box.items.forEach(item => {
      const is_item_done = this.isItemDone(item, shipment_data.boxes)
      if(is_item_done) {
        const itemIdx = shipment_data.items.findIndex(it => it.id === item.id)
        shipment_data.items[itemIdx].is_item_done = is_item_done
        this.moveItemToBottom(itemIdx)
      }
    })

    this.setState({ shipment_data })
  }


  moveItemToBottom = (activeRowIdx) => {
    let shipment_data = this.state.shipment_data
    const theItem = shipment_data.items[activeRowIdx]
    shipment_data.items = shipment_data.items.filter(item => item.id !== theItem.id)
    shipment_data.items.push({ ...theItem })
    this.setState({
      shipment_data,
      activeRowIdx: shipment_data.items.length - 1
    })
  }

  setExpirationDate = (activeRowIdx, date) => {
    let shipment_data = this.state.shipment_data
    shipment_data.items[activeRowIdx] = {...shipment_data.items[activeRowIdx], expiration_date: date}
    this.setState({ shipment_data })
  }

  updateAllBoxesExpDate = (activeRowIdx, date) => {
    let shipment_data = this.state.shipment_data
    shipment_data.boxes.forEach(box => {
      if(box.items[0].id === shipment_data.items[activeRowIdx].id) {
        box.expiration_date = date
      }
    })
    this.setState({ shipment_data })
  }

  deleteBox = (id) => {
    const { shipment_active } = this.props
    this.props.shipmentActiveBoxDelete({
      shipment_id: shipment_active.id,
      box_id: id,
    })
  }

  reNumberBoxes = (boxes) => {
    let boxNumber = 1
    boxes.forEach(box => {
      box.boxNumber = boxNumber
      boxNumber++
    })
    return boxes
  }

  confirmMissingUnits = () => {
    this.setState({
      confirm_missing_units_working: true,
      showConfirmMissingUnitsDialog: false
    })
    setTimeout(() => this.setState({ confirm_missing_units_working: false }), 2000)
  }

  calulateRestQuantity = (item, boxes) => {
    var total = item.quantity;
    boxes.forEach(box => {
      box.items.forEach(box_item => {
        if(item.seller_sku === box_item.seller_sku){
          total = total - box_item.quantity;
        }
      })
    })
    return total;
  }

  setPackingInformation = () => {
    const { shipment_active } = this.props;
    this.props.shipmentActiveSetPackageInfo({
      action: "set-packing-info",
      shipment_id: shipment_active["id"],
    });
  }

  render() {
    const {
      shipment_active,
      shipment_active_working
    } = this.props;
    const { shipment_data, activeRowIdx } = this.state

    console.log('shipment data: ', shipment_active)
    const progressNumber = this.calculateAllItemsDone()
    const progressPercent = progressNumber/shipment_data?.total_qty*100
      /*
    const currentItemBoxes = shipment_data.boxes.filter(box =>
      !box.isMultiSKU && (box.item_data.id === shipment_data.items[activeRowIdx].id)
    )
    const multiSKUBoxes = shipment_data.boxes.filter(box => box.isMultiSKU)
    */
    const shipment_total_qty = shipment_active?.items?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);
    const shipment_in_boxes_qty = shipment_active?.boxes?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);

    return (
      <div>
        <div className="mb-5 flex justify-between items-center">
          <div>
            <div className="flex gap-4">
              <span className="text-slate-500">View Active Shipment</span>
              <span className="text-green-700" role="button" onClick={() => this.props.history.goBack()}>&larr; Back</span>
            </div>
            <h1 className="font-semibold text-xl uppercase">{`${shipment_active?.name} (ID: ${shipment_active?.shipment_confirmation_id})`}</h1>
          </div>
          <div className="flex items-center gap-8">
            <div>
              <span className="text-slate-500">Destination</span>
              <span className="block font-semibold">{shipment_active?.warehouse_id}</span>
            </div>
            <div>
              <span className="text-slate-500">Shipment Type</span>
              <span className="block font-semibold">{shipment_active?.shipment?.shipment_type_human}</span>
            </div>
            <div>
              <span className="text-slate-500">Amazon Partnered</span>
              <span className="block font-semibold">{shipment_active?.shipment?.amazon_partnered ? 'Partnered' : 'Non-Partnered'}</span>
            </div>
            <div>
              <span className="text-slate-500">Status</span>
              <span className="block font-semibold">{shipment_active?.status?.toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
          {/*
            <Button className="btn-tertiary" onClick={() => this.setState({ showPrintCustomLabelsModal: true })}>
              <IconPrint className="mr-1 size-4 inline" />
              <span>Print Custom Labels</span>
            </Button>
            <PrintCustomLabelsModal
              open={this.state.showPrintCustomLabelsModal}
              onClose={() => this.setState({ showPrintCustomLabelsModal: false })}
            />
            {shipment_active?.shipment?.shipment_type_human === 'LTL' &&
            <>
            <Button className="btn-tertiary" onClick={() => this.setState({ showPrintPalletLabelsModal: true })}>
              <IconPrint className="mr-1 size-4 inline" />
              <span>Print Pallet Labels</span>
            </Button>
            <PrintPalletLabelsModal
              open={this.state.showPrintPalletLabelsModal}
              onClose={() => this.setState({ showPrintPalletLabelsModal: false })}
            />
            </>
            }
          */}
          </div>
          <div className="flex items-center gap-2">
            <Button
              className="btn-tertiary"
              disabled={this.state.sync_working}
              onClick={this.syncShipment}
            >
              <IconCloudSync className="mr-1 size-4 inline" />
              <span>Sync</span>
            </Button>
            <LoadingDialog text="Synchronizing with Amazon..." open={this.state.sync_working} />
            <Button
              className="btn-tertiary"
              onClick={() => this.setState({ viewAllBoxes: true })}
            >
              <IconBoxes className="mr-1 size-4 inline" />
              <span>View All Boxes ({shipment_active?.boxes?.length || 0})</span>
            </Button>
            {this.state.viewAllBoxes &&
            <ViewAllBoxesModal
              open={true}
              onClose={() => this.setState({ viewAllBoxes: false })}
              shipment_data={shipment_active}
            />
            }
            {shipment_active?.transportation_options_confirmed &&
                <Button
                  className="btn-primary"
                  onClick={() => this.setState({ showGetLabelsModal: true })}
                  disabled={shipment_active_working}
                >
                  <span>Get Labels</span>
                </Button>
            }
            {this.state.showGetLabelsModal &&
            <GetLabelsModal
              open={true}
              onClose={() => this.setState({ showGetLabelsModal: false })}
              shipmentActiveGetLabels={this.props.shipmentActiveGetLabels}
              shipment_active={shipment_active}
              shipment_active_working={shipment_active_working}
            />
            }
            {(shipment_total_qty === shipment_in_boxes_qty
              && !shipment_active?.package_information_set
              && shipment_active?.shipment?.shipment_type_human === "LTL"
            ) &&
              <Button
                className="btn-primary"
                onClick={() => this.setPackingInformation()}
                disabled={shipment_active_working}
              >
                <span>Set Packing Information</span>
              </Button>
            }
            {(shipment_active?.package_information_set && !shipment_active?.transportation_options_confirmed) &&
            <Button
              className="btn-primary"
              onClick={() => this.setState({ showCompleteShipmentModal: true })}
              //disabled={progressNumber < shipment_data.total_qty}
            >
              <IconShipping className="mr-1 size-4 inline" />
              <span>{shipment_active?.shipment?.amazon_partnered ? 'Buy Shipping and Complete Shipment' : 'Complete Shipment'}</span>
            </Button>
            }
            {this.state.showCompleteShipmentModal &&
            <CompleteShipment
              open={true}
              onClose={() => this.setState({ showCompleteShipmentModal: false })}
            />
            // <>
            // <CompleteShipmentSPDModal
            //   open={shipment_data.shipment_type === 'SPD'}
            //   onClose={() => this.setState({ showCompleteShipmentModal: false })}
            //   shipment_data={shipment_data}
            // />
            // <CompleteShipmentLTLModal
            //   open={shipment_data.shipment_type === 'LTL'}
            //   onClose={() => this.setState({ showCompleteShipmentModal: false })}
            //   shipment_data={shipment_data}
            // />
            // </>
            }
          </div>
        </div>
        <div className="flex gap-4">
          <div className="basis-2/3">
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
            <div className="flex justify-between items-center mb-8">
              <div className="flex items-center gap-2">
                <Input type="text" placeholder="Title/SKU/ASIN" className="min-w-96" />
                <Button className="btn-tertiary">Search</Button>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  className="btn-tertiary"
                  onClick={() => this.setState({ showConfirmMissingUnitsDialog: true })}
                >
                  <IconCloudSync className="mr-1 size-4 inline" />
                  <span>Confirm Missing Units</span>
                </Button>
                <ConfirmDialog
                  open={this.state.showConfirmMissingUnitsDialog}
                  onClose={() => this.setState({ showConfirmMissingUnitsDialog: false })}
                  type="danger"
                  title="WARNING!"
                  text={<>This will change the quantities of your shipment plan to match you've currently prepped. <br/>
                    <span className="text-red-700">Any units or SKUs not yet prepped will be removed from you shipment plan.</span> <br/><br/>Are you sure want to continue?</>}
                  confirmBtnText="Yes"
                  onConfirm={this.confirmMissingUnits}
                />
                <LoadingDialog text="Synchronizing with Amazon..." open={this.state.confirm_missing_units_working} />
              </div>
            </div>
            <div className="px-1 relative bg-slate-700 text-sm rounded overflow-clip text-center mb-4">
              <div
                className="bg-green-700 absolute left-0"
                style={{ width: `${progressPercent}%` }}
              >&nbsp;</div>
              <span className="z-10 relative text-white">{shipment_in_boxes_qty}/{shipment_total_qty || 0}</span>
            </div>
            <table className="table-auto w-full border-spacing-5 border-collapse">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="px-3">TITLE</th>
                  <th className="px-3">QTY</th>
                  <th className="px-3">NOTES</th>
                  <th className="px-3 text-center">PRINT/ARRANGE</th>
                </tr>
              </thead>
              <tbody>
                {shipment_active?.items?.map((item, idx) => (
                <tr
                  key={`row-${item.id}`}
                  className={`border-t py-3 border-t-slate-200 hover:bg-slate-50 cursor-pointer
                    data-[active]:bg-slate-100 data-[completed]:bg-green-700/15 data-[active]:data-[completed]:bg-green-700/25`}
                  onClick={() => this.setState({ activeRowIdx: idx })}
                  data-active={activeRowIdx === idx ? true : null}
                  data-completed={item.is_item_done ? true : null}
                >
                  <td className="p-3">
                    <div className="flex gap-2">
                      <div className="w-14">
                        <img
                          src={item?.image_url
                            ? item?.image_url
                            : `https://www.amazon.com/images/P/${item.asin1}.01._THUMBZZZ_.jpg`
                          }
                          alt=""
                          className="h-12 relative top-1/2 -translate-y-1/2"
                        />
                      </div>
                      <div>
                        <span className="line-clamp-2 max-w-md text-sm leading-tight">{item.item_name}</span>
                        <small>SKU: {item.seller_sku} / {item?.item_condition_human}</small>
                      </div>
                    </div>
                  </td>
                  <td className="p-3">{this.calulateRestQuantity(item, shipment_active?.boxes || [])}</td>
                  <td className="p-3 text-orange-600">{item.notes}</td>
                  <td className="px-3">
                    <div className="flex gap-2">
                      <Button
                        className="btn-secondary text-center text-green-700 mx-auto leading-none"
                        onClick={() => this.setState({ printItemId: item.id })}
                      >
                        <IconPrint className="size-6" />
                        <span className="text-sm text-green-700/75 leading-none mt-1">{item.done_qty}</span>
                      </Button>
                      <Button
                        className="btn-secondary text-center text-green-700 mx-auto leading-none"
                        disabled={item?.boxes?.length}
                        onClick={() => this.setState({ arrangeBoxId: item.id })}
                      >
                        <IconBox className="size-6"/>
                        <span className="text-sm text-green-700/75 leading-none mt-1">
                        {this.getArrangedQty(item, shipment_data.boxes)}
                        </span>
                      </Button>
                      {this.state.printItemId === item.id &&
                      <PrintItemLabelsModal
                        item_data={item}
                        open={true}
                        onClose={() => this.setState({ printItemId: null })}
                        onExpirationDateChange={date => {
                          this.setExpirationDate(activeRowIdx, date)
                          this.updateAllBoxesExpDate(activeRowIdx, date)
                        }}
                        onPrintDone={printQty => this.onPrintItemLabelsDone(activeRowIdx, printQty)}
                      />
                      }
                      {this.state.arrangeBoxId === item.id &&
                      <BoxArrangementModal
                        item_data={item}
                        boxes={shipment_data.boxes}
                        open={true}
                        onClose={() => this.setState({ arrangeBoxId: null })}
                        onExpirationDateChange={date => this.setExpirationDate(activeRowIdx, date)}
                        onArrangementDone={(boxArrangements) => this.onArrangementDone(boxArrangements)}
                      />
                      }
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          {shipment_active?.related_shipments?.length > 0 &&
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white mt-6">
            <h2 className="font-medium mb-4">Related Shipments</h2>
            <RelatedShipmentsTable
              shipments={shipment_active?.related_shipments ? shipment_active.related_shipments : []}
            />
          </div>
          }
          </div>
          {shipment_active?.shipment?.shipment_type_human === "LTL" &&
          <div className="basis-1/3">
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-medium">Multi SKU Boxes</h2>
                <Button
                  className="btn-secondary btn-sm"
                  onClick={() => this.setState({ showMultiSKUBoxModal: true })}
                >Create Multi SKU Box</Button>
                {this.state.showMultiSKUBoxModal &&
                <MultiSKUBoxModal
                  open={true}
                  onClose={() => this.setState({ showMultiSKUBoxModal: false })}
                  shipment={shipment_active}
                  onDone={box => this.multiSKUBoxDone(box)}
                />
                }
                {this.state.viewMultiSKUBoxId &&
                <ViewMultiSKUBoxModal
                  open={true}
                  onClose={() => this.setState({ viewMultiSKUBoxId: null })}
                  box={shipment_active?.boxes?.find(box => box.id === this.state.viewMultiSKUBoxId)}
                />
                }
              </div>
              {shipment_active?.boxes ?
                <BoxArrangementsTable
                  boxes={shipment_active?.boxes}
                  onDeleteBox={boxId => this.deleteBox(boxId)}
                  onViewMultiSKUBox={boxId => this.setState({ viewMultiSKUBoxId: boxId })}
                  isMultiSKU={true}
                />
              : <p className="text-slate-500">Create box(es) with multiple SKUs in it.</p>
              }
            </div>
            {/*
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white mt-4">
              <h2 className="font-medium mb-2">Single SKU Boxes</h2>
              <div className="flex gap-2 mb-4">
                <div className="w-8">
                  <img
                    src={shipment_data.items[activeRowIdx].image_url
                      ? shipment_data.items[activeRowIdx].image_url
                        : productNoImage
                    }
                    alt=""
                    className="h-8 relative top-1/2 -translate-y-1/2"
                  />
                </div>
                <div>
                  <span className="max-w-96 line-clamp-2 text-sm leading-tight">{shipment_data.items[activeRowIdx].item_name}</span>
                </div>
              </div>
              {activeRowIdx >= 0 ?
                currentItemBoxes?.length ?
                <BoxArrangementsTable
                  boxes={currentItemBoxes}
                  onDeleteBox={boxId => this.deleteBox(activeRowIdx, boxId)}
                />
                : <p className="text-slate-500">Please arrange box(es) for this item.</p>
              : <p className="text-slate-500">Please select a row item on the left.</p>
              }
            </div>
            */}
          </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(connect(
  state => ({
    userData: state.User.get("userData"),
    shipment_active_working: state.Shipment.get("shipment_active_working"),
    shipment_active: state.Shipment.get("shipment_active"),
  }),
  {
    shipmentGetActive,
    shipmentActiveBoxDelete,
    shipmentActiveSetPackageInfo,
    shipmentActiveGetLabels,
  }
)(ViewActiveShipment));
