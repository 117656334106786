import React, { Component } from 'react'
import { connect } from "react-redux";
import Loading from "../../components/Loading"
import { Button, Input } from "@headlessui/react"
import { Link } from "react-router-dom"
import Pagination from "../../components/Pagination"
import { ReactComponent as IconDownload } from "../../assets/icons/icon-download.svg"
import { ReactComponent as IconMapLocation } from "../../assets/icons/icon-map-location.svg"
import { ReactComponent as IconInput } from "../../assets/icons/icon-input.svg"
import { Tooltip } from "react-tooltip"
import ViewTrackingNumbersModal from "./ViewTrackingNumbersModal"
import AddTrackingNumbersModal from "./AddTrackingNumbersModal"
import ConfirmDialog from "../../components/ConfirmDialog"
import shipmentActions from "../../redux/shipment/actions";
import moment from "moment-timezone";
import { flagCA, flagGB, flagUS } from "../../assets/images"

const {
  shipmentGetActiveAll,
  shipmentCancelInboundPlan,
} = shipmentActions;

const flagMap = {
  'US' : flagUS,
  'CA' : flagCA,
  'GB' : flagGB
}

class Shipped extends Component {
  state = {
    showTrackingShipmentId: null,
    showAddTrackingShipmentId: null,
    cancelId: null
  }

  componentDidMount() {
    this.props.shipmentGetActiveAll({shipped: true});
  }

  render() {
    const {
      shipments_active,
      shipments_active_working,
      shipments_working,
    } = this.props;

    return (
      <div>
        <h1 className="font-semibold text-xl uppercase mb-5">Shipped</h1>
        {shipments_active_working ?
        <Loading />
        :
        <>
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          {shipments_active?.data?.length > 0 ?
          <>
            <div>
              <div className="flex items-center gap-2 mb-6">
                <Input type="text" placeholder="Shipment ID/Name" className="min-w-96" />
                <Button className="btn-tertiary">Search</Button>
              </div>
            </div>
            <table className="table-auto w-full border-spacing-5 border-collapse text-[15px]">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="px-3">SHIPMENT ID</th>
                  <th className="px-3">SHIPMENT NAME</th>
                  {/*<th className="px-3">RECEIVED</th>*/}
                  <th className="px-3">STATUS</th>
                  <th className="px-3">DESTINATION</th>
                  <th className="px-3">SHIP-FROM</th>
                  <th className="px-3">TYPE</th>
                  <th className="px-3">CREATED</th>
                  <th className="px-3"></th>
                </tr>
              </thead>
              <tbody>
                {shipments_active?.data?.map(item => (
                <tr key={`shipment-${item.id}`} className="border-t py-3 border-slate-200">
                  <td className="p-3">
                    <Link to={`/app/shipment/shipped/view/${item.id}`}>{item.shipment_confirmation_id}</Link>
                  </td>
                  <td className="p-3">{item.name}</td>
                  {/*
                  <td className="p-3">
                    <div className="px-1 h-2 relative bg-slate-700 text-xs text-white rounded overflow-clip text-center">
                      <div
                        className="bg-green-700 absolute left-0"
                        style={{ width: `${item.received_qty/item.total_qty*100}%` }}
                      >&nbsp;</div>
                    </div>
                    <div className="z-10 relative text-slate-500 text-center text-sm mt-1">{item.received_qty}/{item.total_qty}</div>
                  </td>
                  */}
                  <td className="p-3"><span className="uppercase">{item.status}</span></td>
                  <td className="p-3">
                    <div className="flex">
                      <img src={flagMap[item.destination_address?.countryCode]} alt="" className="w-4 mr-2" />
                      <span className="leading-none">{item.warehouse_id}<br/>{`${item.destination_address?.city}, ${item.destination_address?.stateOrProvinceCode}`}</span>
                    </div>
                  </td>
                  <td className="p-3">{item.source_address?.name}</td>
                  <td className="p-3">
                    {item?.shipment?.shipment_type_human}
                    {item?.shipment?.shipment_type_human === 'LTL' &&
                    <>
                      <IconDownload
                        className="size-5 text-slate-500 ml-1 inline pb-1"
                        role="button"
                        data-tooltip-id="t-download"
                      />
                      <Tooltip id="t-download" content="Download Bill of Ladding" />
                    </>
                    }
                  </td>
                  <td className="p-3">{moment(item.created_at).format("LLL")}</td>
                  <td className="p-3">
                    <div className="flex gap-3">
                    {item?.tracking_data?.length ?
                    <>
                      <IconMapLocation
                        className="size-5 text-slate-500"
                        data-tooltip-id="t-track"
                        role="button"
                        onClick={() => this.setState({ showTrackingShipmentId: item.shipment_id })}
                      />
                      <Tooltip id="t-track" content="View Tracking Numbers" />
                      {this.state.showTrackingShipmentId === item.shipment_id &&
                      <ViewTrackingNumbersModal
                        open={true}
                        onClose={() => this.setState({  showTrackingShipmentId: null })}
                        carriers={item.tracking_data}
                      />
                      }
                    </>
                    :
                    <>
                      <IconInput
                        className="size-5 text-slate-500"
                        role="button"
                        data-tooltip-id="t-input-tracking"
                        onClick={() => this.setState({ showAddTrackingShipmentId: item.shipment_id })}
                      />
                      <Tooltip id="t-input-tracking" content="Add Tracking Numbers" />
                      {this.state.showAddTrackingShipmentId === item.shipment_id &&
                      <AddTrackingNumbersModal
                        open={true}
                        onClose={() => this.setState({ showAddTrackingShipmentId: null })}
                        packages={item.shipment_type === 'SPD' ? item.boxes : item.pallets}
                      />
                      }
                    </>
                    }
                    <Button
                      className="btn-tertiary btn-sm text-red-500"
                      onClick={() => this.props.shipmentCancelInboundPlan({
                        shipment: item?.shipment?.id,
                      })}
                      disabled={shipments_working}
                    >Cancel Inbound Plan</Button>
                    {this.state.cancelId === item.shipment_id &&
                    <ConfirmDialog
                      open={true}
                      onClose={() => this.setState({ cancelId: false })}
                      type="danger"
                      title="WARNING!"
                      text="Are you sure want to cancel?"
                      confirmBtnText="Yes"
                      onConfirm={() => {}}
                    />
                    }
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </>
          : <p className="text-slate-500">Shipments sent to Amazon and marked 'shipped' will be shown here.</p>
          }
          </div>
          <div className="flex justify-end mt-4">
            <Pagination
              data={{ page: 1, pages: 2 }}
              //callback={}
            />
          </div>
        </>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    shipments_active_working: state.Shipment.get("shipments_active_working"),
    shipments_active: state.Shipment.get("shipments_active"),
    shipments_working: state.Shipment.get("shipments_working"),
  }),
  {
    shipmentGetActiveAll,
    shipmentCancelInboundPlan,
  }
)(Shipped);
