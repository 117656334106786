import { Map } from "immutable";

const initState = new Map({
  shipments: {},
  shipments_working: false,
  shipment_working: false,
  shipment: null,
  shipments_active: {},
  shipments_active_working: false,
  shipment_active: null,
  shipment_active_working: false,
});

export default function shipmentReducer(state = initState, action) {
  switch (action.type) {

    case "SHIPMENT_BOX_DELETE":
      return state
        .set("shipment_working", true)
    case "SHIPMENT_BOX_DELETE_SUCCESS":
      let shipment_box_delete = {...state.get("shipment")}
      shipment_box_delete.boxes = shipment_box_delete.boxes.filter(item => item.id !== action.data.data.id);
      return state
        .set("shipment", {...shipment_box_delete})
        .set("shipment_working", false)
    case "SHIPMENT_BOX_DELETE_FAILED":
      return state
        .set("shipment_working", false)

    case "SHIPMENT_BOX_CREATE":
      return state
        .set("shipment_working", true)
    case "SHIPMENT_BOX_CREATE_SUCCESS":
      let shipment_box_create = {...state.get("shipment")}
      if(!shipment_box_create?.boxes){ shipment_box_create.boxes = []; }
      shipment_box_create.boxes.push({...action.data.data});
      return state
        .set("shipment", {...shipment_box_create})
        .set("shipment_working", false)
    case "SHIPMENT_BOX_CREATE_FAILED":
      return state
        .set("shipment_working", false)

    case "SHIPMENT_ACTIVE_GET_LABELS":
      return state
        .set("shipment_active_working", true)
    case "SHIPMENT_ACTIVE_GET_LABELS_SUCCESS":
      return state
        .set("shipment_active_working", false)
    case "SHIPMENT_ACTIVE_GET_LABELS_FAILED":
      return state
        .set("shipment_active_working", false)

    case "SHIPMENT_CANCEL_INBOUND_PLAN":
      return state
        .set("shipments_working", true)
    case "SHIPMENT_CANCEL_INBOUND_PLAN_SUCCESS":
      var shipments_plan_cancel = {...state.get("shipments")}
      var shipments_active_plan_cancel = {...state.get("shipments_active")}
      if(shipments_plan_cancel?.data){
        shipments_plan_cancel.data = shipments_plan_cancel.data.map(item => {
          if(item.id === action.data.data.id){
            return action.data.data;
          }
          return item;
        })
      }
      if(shipments_active_plan_cancel?.data){
        shipments_active_plan_cancel.data = shipments_active_plan_cancel.data.filter(item => item?.shipment?.id !== action.data.data.id);
      }
      return state
        .set("shipments", {...shipments_plan_cancel})
        .set("shipments_active", {...shipments_active_plan_cancel})
        .set("shipments_working", false)
    case "SHIPMENT_CANCEL_INBOUND_PLAN_FAILED":
      return state
        .set("shipments_working", false)

    case "SHIPMENT_ACTIVE_CONFIRM_TRANSPORTATION_OPTIONS":
      return state
        .set("shipment_active_working", true)
    case "SHIPMENT_ACTIVE_CONFIRM_TRANSPORTATION_OPTIONS_SUCCESS":
      return state
        .set("shipment_active", {...state.get("shipment_active"), ...action.data.data})
        .set("shipment_active_working", false)
    case "SHIPMENT_ACTIVE_CONFIRM_TRANSPORTATION_OPTIONS_FAILED":
      return state
        .set("shipment_active_working", false)

    case "SHIPMENT_ACTIVE_GET_DELIVERY_WINDOW":
      return state
        .set("shipment_active_working", true)
    case "SHIPMENT_ACTIVE_GET_DELIVERY_WINDOW_SUCCESS":
      return state
        .set("shipment_active", {...state.get("shipment_active"), ...action.data.data})
        .set("shipment_active_working", false)
    case "SHIPMENT_ACTIVE_GET_DELIVERY_WINDOW_FAILED":
      return state
        .set("shipment_active_working", false)

    case "SHIPMENT_ACTIVE_SET_PACKAGE_INFO":
      return state
        .set("shipment_active_working", true)
    case "SHIPMENT_ACTIVE_SET_PACKAGE_INFO_SUCCESS":
      return state
        .set("shipment_active", {...state.get("shipment_active"), ...action.data.data})
        .set("shipment_active_working", false)
    case "SHIPMENT_ACTIVE_SET_PACKAGE_INFO_FAILED":
      return state
        .set("shipment_active_working", false)

    case "SHIPMENT_ACTIVE_BOX_DELETE":
      return state
        .set("shipment_active_working", true)
    case "SHIPMENT_ACTIVE_BOX_DELETE_SUCCESS":
      let shipment_box_active_delete = {...state.get("shipment_active")}
      shipment_box_active_delete.boxes = shipment_box_active_delete.boxes.filter(item => item.id !== action.data.data.id);
      return state
        .set("shipment_active", {...shipment_box_active_delete})
        .set("shipment_active_working", false)
    case "SHIPMENT_ACTIVE_BOX_DELETE_FAILED":
      return state
        .set("shipment_active_working", false)

    case "SHIPMENT_ACTIVE_BOX_CREATE":
      return state
        .set("shipment_active_working", true)
    case "SHIPMENT_ACTIVE_BOX_CREATE_SUCCESS":
      let shipment_box_active_create = {...state.get("shipment_active")}
      if(!shipment_box_active_create?.boxes){ shipment_box_active_create.boxes = []; }
      shipment_box_active_create.boxes.push({...action.data.data});
      return state
        .set("shipment_active", {...shipment_box_active_create})
        .set("shipment_active_working", false)
    case "SHIPMENT_ACTIVE_BOX_CREATE_FAILED":
      return state
        .set("shipment_active_working", false)

    case "SHIPMENT_GET_ACTIVE":
      return state
        .set("shipment_active", null)
        .set("shipment_active_working", true)
    case "SHIPMENT_GET_ACTIVE_SUCCESS":
      return state
        .set("shipment_active", {...action.data.data})
        .set("shipment_active_working", false)
    case "SHIPMENT_GET_ACTIVE_FAILED":
      return state
        .set("shipment_active", null)
        .set("shipment_active_working", false)

    case "SHIPMENT_GET_ACTIVE_ALL":
      return state
        .set("shipments_active_working", true)
    case "SHIPMENT_GET_ACTIVE_ALL_SUCCESS":
      return state
        .set("shipments_active", {...action.data.data})
        .set("shipments_active_working", false)
    case "SHIPMENT_GET_ACTIVE_ALL_FAILED":
      return state
        .set("shipments_active_working", false)

    case "SHIPMENT_PLAN_CREATE":
      return state
        .set("shipment_working", true)
    case "SHIPMENT_PLAN_CREATE_SUCCESS":
      return state
        .set("shipment", {...state.get("shipment"), ...action.data.data})
        .set("shipment_working", false)
    case "SHIPMENT_PLAN_CREATE_FAILED":
      return state
        .set("shipment_working", false)

    case "SHIPMENT_GET":
      return state
        .set("shipment", null)
        .set("shipments_working", true)
    case "SHIPMENT_GET_SUCCESS":
      return state
        .set("shipment", {...action.data.data})
        .set("shipments_working", false)
    case "SHIPMENT_GET_FAILED":
      return state
        .set("shipment", null)
        .set("shipments_working", false)

    case "SHIPMENT_UPDATE":
      return state
        .set("shipments_working", true)
    case "SHIPMENT_UPDATE_SUCCESS":
      var shipments_update = {...state.get("shipments")}
      if(shipments_update?.data){
        shipments_update.data = shipments_update.data.map(item => {
          if(item.id === action.data.data.id){
            return action.data.data;
          }
          return item;
        })
      }
      return state
        .set("shipments", {...shipments_update})
        .set("shipments_working", false)
    case "SHIPMENT_UPDATE_FAILED":
      return state
        .set("shipments_working", false)

    case "SHIPMENT_GET_ALL":
      return state
        .set("shipments_working", true)
    case "SHIPMENT_GET_ALL_SUCCESS":
      return state
        .set("shipments", {...action.data.data})
        .set("shipments_working", false)
    case "SHIPMENT_GET_ALL_FAILED":
      return state
        .set("shipments_working", false)

    case "SHIPMENT_CREATE":
      return state
        .set("shipments_working", true)
    case "SHIPMENT_CREATE_SUCCESS":
      return state
        .set("shipments_working", false)
    case "SHIPMENT_CREATE_FAILED":
      return state
        .set("shipments_working", false)

		default:
			return state;
  }
}
