import { Field, Label } from "@headlessui/react"
import React, { Component } from 'react'
import SelectPrepType from "../../components/SelectPrepType"
import SelectPrepCategory from "../../components/SelectPrepCategory"

class SettingGeneral extends Component {
  render() {
    return (
      <div className="flex">
        <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white basis-1/3">
          <h2 className="font-semibold mb-5">Default Prep Info</h2>
          <Field className="field">
            <Label>Default prep category</Label>
            <SelectPrepCategory
              onChange={selected => {
                console.log(selected)
              }}
            />
          </Field>
          <Field className="field mt-4">
            <Label>Default prep type</Label>
            <SelectPrepType
              onChange={selected => {
                console.log(selected)
              }}
            />
          </Field>
        </div>
      </div>
    )
  }
}

export default SettingGeneral