import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogPanel,
  //Disclosure,
  //DisclosureButton,
  //DisclosurePanel
} from "@headlessui/react"
import Spinner from "../../components/Spinner"
import { flagCA, flagGB, flagUS } from "../../assets/images"
//import Caret from "../../components/Caret"
import SelectPlacementOptions from "./SelectPlacementOptions"
import SelectPackingOptions from "./SelectPackingOptions"
import PackingGroupItemsModal from "./PackingGroupItemsModal"
import MultiSKUBoxModal from "../ActiveShipments/MultiSKUBoxModal"
import ViewMultiSKUBoxModal from "../ActiveShipments/ViewMultiSKUBoxModal"
import BoxArrangementsTable from "../ActiveShipments/BoxArrangementsTable"

const flagMap = {
  'US' : flagUS,
  'CA' : flagCA,
  'GB' : flagGB
}

class ShipmentPlanner extends Component {
  state = {
    proposed_shipments_in_progress: false,
    proposed_shipments_done: false,
    showPackingGroup: null,
    packing_option_selected: null,
  }

  planShipping = (action) => {
    this.props.shipmentPlanCreate({
      action: action,
      shipment_id: this.props.shipment.id
    });
  }

  confirmPackingOption = (packing_option_id) => {
    this.props.shipmentPlanCreate({
      action: "confirm-packing-option",
      shipment_id: this.props.shipment.id,
      packing_option_id: packing_option_id,
    });
  }

  calculateTopStats = () => {
    const { items } = this.props;
    return (
      <div className="flex flex-row gap-4">
        <span><strong className="text-lg">{items.length}</strong> SKUs</span>
        <span>
          <strong
            className="text-lg"
          >{items.length ? items.map(item => parseInt(item.quantity)).reduce((a,b)=>a+b) : 0}
          </strong> Units
        </span>
      </div>
    )
  }

  selectPlacementOption = (select) => {
    this.props.shipmentPlanCreate({
      action: "select_placement_option",
      shipment_id: this.props.shipment.id,
      placement_option_id: select.value,
    });
  }

  selectPackingOption = (select) => {
    this.setState({packing_option_selected: select})
  }

  getPackingGroupItems = () => {
    this.props.shipmentPlanCreate({
      action: "list-packing-option-items",
      shipment_id: this.props.shipment.id,
      packing_group_id: this.state.showPackingGroup,
    });
  }

  testBox = () => {
    this.props.shipmentPlanCreate({
      action: "test-box",
      shipment_id: this.props.shipment.id,
    });
  }

  deleteBox = (id) => {
    const { shipment } = this.props
    this.props.shipmentBoxDelete({
      shipment_id: shipment.id,
      box_id: id,
    })
  }

  setPackingInformation = () => {
    const { shipment } = this.props;
    this.props.shipmentPlanCreate({
      action: "set-packing-info",
      shipment_id: shipment["id"],
    });
  }

  render() {
    const {
      shipment_working,
      shipment: shipment_data,
    } = this.props;

    const shipment_total_qty = shipment_data?.items_inventory?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);
    const shipment_in_boxes_qty = shipment_data?.boxes?.map(item => {
      return item.quantity;}
    ).reduce((a, b) => a + b, 0);
    console.log(
      "SHIPMENT DATA:",
      shipment_data,
      shipment_total_qty,
      shipment_in_boxes_qty,
      shipment_data?.packing_options_data,
    )

    return (
      <div>
        <h3 className="font-medium text-lg mb-2">Plan Shipping</h3>
        {!shipment_data?.inbound_plan_created_success &&
          <div className="flex justify-between items-center">
            {this.calculateTopStats()}
            <Button
              className="btn-primary"
              onClick={() => this.planShipping("create_inbound_plan")}
              disabled={shipment_working}
            >Plan Shipping</Button>
          </div>
        }
        {
          (
            (
              (shipment_data?.inbound_plan_created_success
                && shipment_data?.shipment_type_human === "LTL"
              ) || (
                shipment_data?.shipment_type_human === "SPD"
                  && shipment_data?.packing_option_selected
                  && (shipment_total_qty === shipment_in_boxes_qty)
                  && shipment_data?.package_information_set
                  && !shipment_data?.placement_option_selected
              )
            ) && (
              !shipment_data?.shipment_moved_to_active
            )
          ) &&
          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-4">
            </div>
            <Button
              className="btn-primary"
              onClick={() => this.planShipping("generate_placement_options")}
              disabled={shipment_working}
            >Generate Placement Options</Button>
          </div>
        }
        {(shipment_data?.inbound_plan_created_success
            && shipment_data?.shipment_type_human === "SPD"
        ) &&
          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-4">
            {(shipment_data?.packing_options_data
                && this.state.packing_option_selected
            ) &&
              <div className="flex justify-between items-center">
                <div className="flex flex-row gap-4">
                </div>
                <Button
                  className="btn-primary"
                  onClick={() => this.confirmPackingOption(this.state.packing_option_selected?.value)}
                  disabled={shipment_working}
                >Confirm Packing Option</Button>
              </div>
            }
            </div>
            {(!shipment_data?.packing_option_selected
               && shipment_data?.shipment_type_human === "SPD"
            ) &&
            <Button
              className="btn-primary"
              onClick={() => {
                this.setState({packing_option_selected: null});
                this.planShipping("generate_packing_options");
              }}
              disabled={shipment_working}
            >Generate Packing Options</Button>
            }
          </div>
        }
        {((shipment_total_qty === shipment_in_boxes_qty)
            && (shipment_total_qty > 0 && shipment_in_boxes_qty > 0)
            && !shipment_data?.package_information_set
          ) &&
          <Button
            className="btn-primary"
            onClick={() => this.setPackingInformation()}
            disabled={shipment_working}
          >
            <span>Set Packing Information</span>
          </Button>
        }
        {shipment_data?.placement_options_data &&
          <SelectPlacementOptions
            classNames="mt-4"
            placement_options_data={shipment_data?.placement_options_data}
            onChange={(select) => this.selectPlacementOption(select)}
            select_placement_option={shipment_data?.placement_option_selected}
            disabled={shipment_working || shipment_data?.shipment_moved_to_active}
          />
        }
        {shipment_data?.packing_options_data &&
          <SelectPackingOptions
            classNames="mt-4"
            packing_options_data={shipment_data?.packing_options_data}
            onChange={(select) => this.selectPackingOption(select)}
            select_packing_option={shipment_data?.packing_option_selected}
            disabled={shipment_working || shipment_data?.shipment_moved_to_active}
          />
        }
        {this.state.packing_option_selected &&
          <div className="mt-4">
            <h2 className="font-medium mb-1">Packing Groups</h2>
            <div>
              {this.state.packing_option_selected?.data?.packingGroups.map((item, idx) => (
              <div key={`packing-group-${idx}`} className="flex justify-between gap-2">
                <span>{`Packing Group ${idx+1}`}</span>
                <span
                  role="button"
                  className="link text-sm"
                  onClick={() => this.setState({
                    showPackingGroup: item,
                  })}
                >View Items</span>
              </div>
              ))}
            </div>
            {this.state.showPackingGroup &&
            <PackingGroupItemsModal
              open={true}
              onClose={() => this.setState({ showPackingGroup: null })}
              getPackingGroupItems={this.getPackingGroupItems}
              shipment_data={shipment_data}
              shipment_working={shipment_working}
            />
            }
        </div>
        }
        {(shipment_data?.inbound_plan_created_success
            && shipment_data?.shipment_type_human === "SPD"
            && shipment_data?.packing_option_selected
        ) &&
        <React.Fragment>
          <hr className="mt-4" />
          {Object?.keys(shipment_data?.packing_groups_items).map((packing_group_id, idx) => {
            return (
              <React.Fragment>
              <div
                className="flex justify-between items-center my-4"
                style={shipment_data?.shipment_moved_to_active
                  ? {"pointer-events": "none", opacity: 0.4}
                  : null
                }
              >
                <h2 className="font-medium">Boxes Group {idx + 1}</h2>
                <Button
                  className="btn-secondary btn-sm"
                  onClick={() => this.setState({ showMultiSKUBoxModal: true })}
                >Create Multi SKU Box</Button>
                {this.state.showMultiSKUBoxModal &&
                <MultiSKUBoxModal
                  open={true}
                  onClose={() => this.setState({ showMultiSKUBoxModal: false })}
                  shipment={shipment_data}
                  onDone={box => this.multiSKUBoxDone(box)}
                  is_spd={true}
                  packing_group_id={packing_group_id}
                />
                }
                {this.state.viewMultiSKUBoxId &&
                <ViewMultiSKUBoxModal
                  open={true}
                  onClose={() => this.setState({ viewMultiSKUBoxId: null })}
                  box={shipment_data?.boxes?.find(box => box.id === this.state.viewMultiSKUBoxId)}
                />
                }
              </div>
              {shipment_data?.boxes ?
                <BoxArrangementsTable
                  boxes={shipment_data?.boxes.filter(item => item.packing_group === packing_group_id)}
                  onDeleteBox={boxId => this.deleteBox(boxId)}
                  onViewMultiSKUBox={boxId => this.setState({ viewMultiSKUBoxId: boxId })}
                  isMultiSKU={true}
                  isDisabeld={shipment_data?.shipment_moved_to_active}
                />
                : <p className="text-slate-500">Create box(es) with multiple SKUs in it.</p>
              }
              </React.Fragment>
            )})
          }
        </React.Fragment>
        }
        {shipment_data?.inbound_plan_created_failed &&
        <>
          <hr className="mt-6 mb-4" />
          <h3 className="font-medium text-lg mb-2">Shipment plan creating issues present</h3>
        </>
        }
        {(shipment_data?.inbound_plan_created_failed && shipment_data?.inbound_plan_created_data) &&
        <>
          <hr className="mt-6 mb-4" />
          <h3 className="font-medium text-lg mb-2">Shipment plan creating issues</h3>
          <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
            <thead>
              <tr className="border-b-2 border-slate-200">
                <th>ERROR</th>
                <th>MESSAGE</th>
                <th>DETAILS</th>
                <th>SEVERITY</th>
              </tr>
            </thead>
            <tbody>
              {shipment_data?.inbound_plan_created_data?.operationProblems?.map((item, idx) => {
                return (
                  <tr key={`problem-${idx}`} className="border-t py-3 border-slate-200">
                    <td className="py-2">{item?.code}</td>
                    <td className="py-2">{item.message}</td>
                    <td className="py-2">{item.details}</td>
                    <td className="py-2">{item.severity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
        }
        {(shipment_data?.shipments_data?.shipments && !shipment_data?.shipment_moved_to_active) &&
        <>
          <hr className="mt-6 mb-4" />
          <h3 className="font-medium text-lg mb-2">Proposed Shipments by Amazon</h3>
          <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
            <thead>
              <tr className="border-b-2 border-slate-200">
                <th>DESTINATION</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {shipment_data?.shipments_data?.shipments.map(item => (
              <tr key={`plan-${item.amazonReferenceId}`} className="border-t py-3 border-slate-200">
                <td className="py-2">
                  <div className="flex">
                    <img src={flagMap[item?.destination?.address?.countryCode]} alt="" className="w-4 mr-2" />
                    <span className="leading-none">{item?.destination?.warehouseId}<br/>{item.warehouse_location}</span>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex gap-1">
                    <Button i
                      disabled={true}
                      className="btn-secondary btn-sm"
                    >Optimize</Button>
                    <Button
                      disabled={true}
                      className="btn-primary btn-sm"
                    >Accept</Button>
                  </div>
                </td>
              </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="py-2 text-right" colSpan={5}>
                  <Button
                    className="btn-primary btn-sm"
                    onClick={() => this.props.shipmentPlanCreate({
                      action: "accept-all-shipments",
                      shipment_id: this.props.shipment.id,
                    })}
                  >Accept All</Button>
                </td>
              </tr>
            </tfoot>
          </table>
        </>
        }
        <Dialog open={shipment_working} onClose={() => {}}>
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-gray-950/50">
            <DialogPanel className="border bg-white rounded-md shadow-xl relative flex items-center gap-2 px-4 py-2">
              <Spinner className="size-4" /> <span className="text-slate-500">Synchronizing with Amazon...</span>
            </DialogPanel>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default ShipmentPlanner
