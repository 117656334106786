import React, { Component } from 'react'
 import { connect } from "react-redux";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { ToastContainer } from 'react-toastify';
import Caret from "./Caret"
import { NavLink } from "react-router-dom"
import { flagCA, flagGB, flagUS } from "../assets/images"
import authActions from "../redux/auth/actions";
import userActions from "../redux/user/actions";
import 'react-toastify/dist/ReactToastify.css';
//import { Link } from "react-router-dom";

const { userLogout } = authActions;
const {
  userGetData,
} = userActions;

const flagMap = {
  'us' : flagUS,
  'ca' : flagCA,
  'gb' : flagGB
}

const connectedMarketplaces = [
  {id: 'us', name: 'My primary store'},
  {id: 'ca', name: 'My secondary store'},
]

class TopBar extends Component {
  state = {
    active_marketplace: connectedMarketplaces[0],
    user_data_loaded: false,
  }

  componentDidMount = () => {
    if(!this.props.userData?.id){
      this.props.userGetData();
    }
  }

  componentDidUpdate = (pp) => {
    if(!pp.userData?.id && !this.state.user_data_loaded){
      this.props.userGetData();
      this.setState({ user_data_loaded: true });
    }
  }

  render() {
    const { userData } = this.props;
    console.log("userData:", userData)

    return (
      <>
      <div className="flex items-center justify-between py-3 border-b border-b-2 border-slate-100">
        <div className="font-bold leading-none mr-12">
          2D BoxContent
        </div>
        <div className="flex items-center gap-3">
          {/*<Link to="/app/admin" className="btn-link btn-primary btn-sm">ADMIN</Link>*/}
          <Listbox value={this.state.active_marketplace} onChange={selected => this.setState({ active_marketplace: selected })}>
            <ListboxButton as="span" role="button" className="flex items-center gap-1 px-3">
              <img src={flagMap[this.state.active_marketplace.id]} alt="" className="w-4" />
              <span className="text-nowrap">{this.state.active_marketplace.name}</span>
              <Caret />
            </ListboxButton>
            <ListboxOptions anchor="bottom end" className="bg-white rounded shadow-lg border border-slate-100 min-w-40 flex flex-col">
              {connectedMarketplaces.map(item => (
              <ListboxOption key={`connection-${item.id}`} value={item} className="flex items-center gap-1 px-3 py-2 cursor-pointer hover:bg-slate-50">
                <img src={flagMap[item.id]} alt="" className="w-4" />
                <span>{item.name}</span>
              </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
          <Menu>
            <MenuButton className="ml-auto py-0 shadow-none">
              <span>{userData?.username}</span>
              <Caret className="inline ml-1" />
            </MenuButton>
            <MenuItems anchor="bottom end">
              <MenuItem>
                <NavLink to="/app/account" className="text-slate-800">My Account</NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/app/settings" className="text-slate-800">Settings</NavLink>
              </MenuItem>
              <MenuItem>
                <span
                  role="button"
                  onClick={() => this.props.userLogout()}
                  className="text-red-500"
                >Logout</span>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
      <ToastContainer
        hideProgressBar={true}
      />
      </>
    )
  }
}


export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
    userLogout,
    userGetData,
  }
)(TopBar);
