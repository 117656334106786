import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, DialogTitle } from "@headlessui/react"

class PackingGroupItemsModal extends Component {

  componentDidMount = () => {
    this.props.getPackingGroupItems();
  }

  render() {
    const { open, onClose, shipment_data, shipment_working } = this.props

    console.log('aaaaaaaaaaaaaa', shipment_working);
    return (
      <Modal open={open} onClose={onClose} size="xl">
        <DialogTitle className="text-xl font-semibold mb-5">Packing Group Items</DialogTitle>
        <table className="table-auto w-full border-spacing-5 border-collapse">
          <thead>
            <tr className="border-b-2 border-slate-200">
              <th className="px-3">ASIN</th>
              <th className="px-3">FNSKU</th>
              <th className="px-3">MSKU</th>
              <th className="px-3">QTY</th>
              <th className="px-3">WHO LABELS</th>
            </tr>
          </thead>
          <tbody>
            {shipment_working
              ? null
              : shipment_data?.packing_group_items?.map(item => (
                  <tr key={`group-item-${item.asin}`} className="border-t py-3 border-slate-200 text-sm">
                    <td className="p-3">{item.asin}</td>
                    <td className="p-3">{item.fnsku}</td>
                    <td className="p-3">{item.msku}</td>
                    <td className="p-3">{item.quantity}</td>
                    <td className="p-3">{item.labelOwner}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <div className="flex justify-end mt-4">
          <Button
            className="btn-tertiary"
            onClick={onClose}
          >Close</Button>
        </div>
      </Modal>
    )
  }
}

export default PackingGroupItemsModal
