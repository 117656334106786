import React, { Component } from 'react'
import Select from 'react-select'

const options = [
  {value: 'ADULT', label: 'ADULT'},
  {value: 'BABY', label: 'BABY'},
  {value: 'FC_PROVIDED', label: 'FC_PROVIDED'},
  {value: 'FRAGILE', label: 'FRAGILE'},
  {value: 'GRANULAR', label: 'GRANULAR'},
  {value: 'HANGER', label: 'HANGER'},
  {value: 'LIQUID', label: 'LIQUID'},
  {value: 'PERFORATED', label: 'PERFORATED'},
  {value: 'SET', label: 'SET'},
  {value: 'SHARP', label: 'SHARP'},
  {value: 'SMALL', label: 'SMALL'},
  {value: 'TEXTILE', label: 'TEXTILE'},
  {value: 'UNKNOWN', label: 'UNKNOWN'},
  {value: 'NONE', label: 'NONE'},
]

class SelectPrepCategory extends Component {
  render() {
    const { onChange } = this.props

    return (
      <Select
        options={options}
        onChange={selected => onChange(selected)}
        classNames={{
          container: () => 'min-w-60',
          menu: () => 'min-w-60',
        }}
      />
    )
  }
}

export default SelectPrepCategory