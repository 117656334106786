import axios from "axios";
import defaults from "superagent-defaults";
import { getTokenLocal } from "../helpers/auth/utils";
import { createAxiosResponseInterceptorForClient } from "../helpers/auth/token_refresh";
import { appConfig } from "./app";

const apiEndpoints = {
  auth: {
    login: "/auth/login",
    token: {
      refresh: "/auth/token/refresh",
    },
    register: "/user/registration",
    register_sms: "/user/registration/sms",
  },
  user: {
    user: "/user/",
    password: "/user/password",
    settings: "/user/settings",
    address: "/user/address",
    address_all: "/user/address/all",
    amazon_auth: "/user/amazon/auth",
  },
  inventory: {
    all: "/inventory/all",
    search: {
      product: "/inventory/search/product",
    },
    product: "/inventory/product",
  },
  shipments: {
    shipment: "/shipments/shipment",
    shipment_plan: "/shipments/shipment/plan",
    all: "/shipments/all",
    active_all: "/shipments/active/all",
    active: "/shipments/active",
    active_box: "/shipments/active/box",
    shipment_box: "/shipments/shipment/box",
  }
};

const env =
  process.env.REACT_APP_NODE_ENV || appConfig.environments["production"];

const API_ROOT = appConfig.backendApiUrl[env];

const client = axios.create({ baseURL: API_ROOT });

const superagent = defaults();

const request = {
  init: () =>
    superagent.set(
      "Authorization",
      `Bearer ${getTokenLocal().toJS().access_token}`
    ),
  delete: (url) => request.init().delete(url),
  get: (url) => request.init().get(url),
  post: (url) => request.init().post(url),
  put: (url) => request.init().put(url),
};

client.defaults.headers.common["Authorization"] = `Bearer ${
  getTokenLocal().toJS().access_token
}`;

const interceptor = createAxiosResponseInterceptorForClient(client);

interceptor();

export { request, API_ROOT, client, apiEndpoints };
