import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import React, { Component } from 'react'
import AdminUsers from "./AdminUsers"

class Admin extends Component {
  render() {
    return (
      <div>
        <h1 className="font-semibold text-xl mb-5 uppercase">ADMIN</h1>
        <TabGroup>
          <TabList>
            <Tab>Users</Tab>
          </TabList>
          <TabPanels className="mt-4">
            <TabPanel>
              <AdminUsers />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    )
  }
}

export default Admin