import { Button, Field, Input, Label } from "@headlessui/react"
import React, { Component } from 'react'
import { Link } from "react-router-dom";

class ForgotPassword extends Component {
  state = {
    email: '',
    email_sent: false,
    reset_loading: false
  }

  sendResetEmail = () => {
    this.setState({ reset_loading: true })
    setTimeout(() => this.setState({
      reset_loading: false,
      email_sent: true
    }), 1500)
  }

  render() {
    return (
      <div className="container mx-auto flex flex-col h-screen items-center justify-center gap-8">
        <div className="font-bold text-lg">
            2D BoxContent
          </div>
        <div className="px-8 py-8 rounded-md shadow-lg flex flex-col gap-3 w-96 bg-white justify-center">
          <h2 className="font-semibold text-xl">Reset Password</h2>
          {!this.state.email_sent ?
          <>
            <p>Enter email address associated with your account and we'll send the instructions for resetting the password to your inbox.</p>
            <Field className="field">
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="youremail@domain.com"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </Field>
            <Button
              className="btn-primary mt-2"
              disabled={this.state.reset_loading}
              onClick={this.sendResetEmail}
            >
              Reset Password
            </Button>
            <div className="text-sm text-center mt-2">
              <p className="mt-1"><Link to="/login">Login</Link></p>
            </div>
          </>
          :
          <>
            <p>
              An email to reset your password has been sent to your inbox. Please check spam folder if you didn't find it.
            </p>
            <p>
              <span className="text-slate-500">Didn't receive the email?</span> 
              <span className="link ml-2" role="button" onClick={() => {}}>Resend email</span>
            </p>
          </>
        }
        </div>
      </div>
    )
  }
}

export default ForgotPassword