import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Field, Input, Label } from "@headlessui/react"
import {
  CardElement,
} from "@stripe/react-stripe-js";
import Select from 'react-select';
import MaskedInput from "react-text-mask";
import { withRouter } from "react-router";
import authActions from "../../redux/auth/actions";
import { ReactComponent as IconCheck } from '../../assets/icons/icon-check.svg';
import VerifyCodeModal from "./VerifyCodeModal";

const { userRegister, userRegisterSMSSend } = authActions;
const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const countryPhoneOptions = [
  {value: '+1', label: 'US (+1)'},
]
let timer;

class RegisterForm extends Component {
  state = {
    email: "",
    pw: "",
    un: "",
    show_pw: false,
    pw_confirm: "",
    cc_valid: false,
    phone: null,
    phone_country: null,
    showVerifyModal: false,
    resend_code_timer: 0,
    has_min_chars: false,
    has_uppercase: false,
    has_number: false,
    has_symbol: false,
    passwordFocus: false,
    pw_confirm_pristine: true,
    confirm_code_sms: "",
  }

  componentDidMount = () => {
    this.startTimer()
  }

  startTimer = () => {
    this.setState({ resend_code_timer: 30 })
    timer = setInterval(() => {
      let resend_code_timer = this.state.resend_code_timer
      if(resend_code_timer <= 0) {
        clearInterval(timer)
      } else {
        resend_code_timer--
      }
      this.setState({ resend_code_timer })
    }, 1000)
  }

  updateValue = (key, data) => {
    this.setState({ [key]: data });
  }

  createPhoneNumber = () => {
    const {phone, phone_country} = this.state;
    if(!phone || !phone_country){ return "";}
    return `${phone_country?.value}${phone}`
  }

  onFormSubmit = () => {
    const card = this.props.elements.getElement(CardElement);
    this.props.stripe
      .createToken(card)
      .then(response => {
        if(response.token){
          this.setState({
            stripe_error: null,
          })
          let authData = {
            username: this.state.un,
            email: this.state.email,
            password: this.state.pw,
            stripe_token: response.token.id,
            stripe_plan: "2D_BASE_PLAN",
            phone: this.createPhoneNumber(),
            confirm_code_sms: this.state.confirm_code_sms,
          };
          this.props.userRegister(authData);
        } else {
          this.setState({stripe_error: response?.error?.message})
        }
      })
  }

  passwordRequirementsCheck = (value) => {
    this.setState({
      pw: value,
      pw_pristine: false
    })

    // check min chars
    if(value.length >= 8) {
      this.setState({ has_min_chars: true })
    } else {
      this.setState({ has_min_chars: false })
    }

    // check if has uppercase
    if(value.toLowerCase() !== value) {
      this.setState({ has_uppercase: true })
    } else {
      this.setState({ has_uppercase: false })
    }

    // check if has number
    const matches = value.match(/\d+/g);
    if (matches !== null) {
      this.setState({ has_number: true })
    } else {
      this.setState({ has_number: false })
    }

    // check if has symbol
    const matches2 = value.match(/[!-/:-@[-`{-~]/gm);
    if (matches2 !== null) {
      this.setState({ has_symbol: true })
    } else {
      this.setState({ has_symbol: false })
    }
  }

  checkPW = () => {
    const {
      has_min_chars,
      has_uppercase,
      has_number,
      has_symbol,
    } = this.state;
    return has_min_chars && has_uppercase && has_number && has_symbol;
  }

  render() {
    return (
      <div>
        <div className="grid grid-cols-2 gap-8">
          <Field className="field">
            <Label>Email</Label>
            <Input
              type="email"
              placeholder="youremail@domain.com"
              onChange={e => this.updateValue("email", e.target.value)}
              value={this.state.email}
            />
          </Field>
          <Field className="field">
            <Label>Username</Label>
            <Input
              type="text"
              placeholder="Provide username"
              onChange={e => this.updateValue("un", e.target.value)}
              value={this.state.un}
            />
          </Field>
        </div>
        <div className="grid grid-cols-2 gap-8 mt-4">
          <Field className="field relative">
            <Label>Password</Label>
            <div className="relative">
              <Input
                type={this.state.show_pw ? 'text' : 'password'}
                className="w-full"
                autoComplete="new-password"
                placeholder="Provide password"
                onChange={e => this.passwordRequirementsCheck(e.target.value)}
                onFocus={() => this.setState({ passwordFocus: true })}
                onBlur={() => this.setState({ passwordFocus: false })}
                value={this.state.pw}
              />
              <small
                className="absolute top-3 right-2 cursor-pointer text-slate-500"
                onClick={() => this.setState({ show_pw: !this.state.show_pw })}
              >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
            </div>
            {(!this.checkPW() && this.state.passwordFocus) &&
            <PasswordCheckPopover
              has_min_chars={this.state.has_min_chars}
              has_uppercase={this.state.has_uppercase}
              has_number={this.state.has_number}
              has_symbol={this.state.has_symbol}
            />
            }
          </Field>
          <Field className="field">
            <Label>Confirm Password</Label>
            <div className="relative">
              <Input
                type={this.state.show_pw ? 'text' : 'password'}
                className="w-full"
                placeholder="Confirm password"
                onChange={e => {
                  this.updateValue("pw_confirm", e.target.value)
                  this.setState({ pw_confirm_pristine: false })
                }}
                value={this.state.pw_confirm}
              />
              <small
                className="absolute top-3 right-2 cursor-pointer text-slate-500"
                onClick={() => this.setState({ show_pw: !this.state.show_pw })}
              >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
            </div>
          </Field>
        </div>
        {!this.state.pw_confirm_pristine && (this.state.pw !== this.state.pw_confirm) &&
        <p className="text-red-700 text-sm mt-1">Password doesn't match</p>
        }
        <div className="grid grid-cols-3 gap-8 mt-4">
          <Field className="field">
            <Label>Country</Label>
            <div className="d-flex gap-2">
              <div style={{ minWidth: 170 }}>
                <Select
                  options={countryPhoneOptions}
                  onChange={(e) => this.setState({phone_country: e})}
                />
              </div>
            </div>
          </Field>
          <Field className="field">
            <Label>Phone Number</Label>
              <MaskedInput
                mask={phoneMask}
                onChange={e => this.setState({ phone: e.target.value.replace(/\D+/g, '') })}
                className="form-control"
                showMask={true}
                keepCharPositions={true}
                value={this.state.phone}
              />
          </Field>
          <Field className="field">
            <Label>&nbsp;</Label>
              <Button
                className="btn-secondary"
                onClick={(e) => {
                  this.setState({
                    showVerifyModal: true,
                    confirm_code_sms: "",
                  })
                  this.props.userRegisterSMSSend({
                    username: this.state.un,
                    email: this.state.email,
                    phone: this.createPhoneNumber(),
                  })
                }}
                disabled={
                  this.props.registration_working
                    || !this.state.un
                    || !this.state.pw
                    || !this.state.email
                    || (this.state.pw !== this.state.pw_confirm)
                    || !this.state.phone
                    || !this.state.phone_country
                }
              >Send Code</Button>
          </Field>
        </div>
        <p className="text-slate-500 text-sm">We will send confirmation code to this number.</p>
        {this.state.showVerifyModal &&
        <VerifyCodeModal
          open={true}
          onClose={() => this.setState({ showVerifyModal: false })}
          onVerify={(code) => this.setState({confirm_code_sms: code})}
          onResendCode={() => this.props.userRegisterSMSSend(
            {
              username: this.state.un,
              email: this.state.email,
              phone: this.createPhoneNumber(),
            }
          )}
          registration_working={this.props.registration_working}
        />
        }
        <Field className="field mt-4">
          <Label>Payment Information</Label>
          {this.props.stripe &&
            <CardElement
              onChange={(e) => {
                this.setState({ cc_valid: e.complete && !e.error })
              }}
            />
          }
        </Field>
        <div className="mt-4">
          <Button
            className="btn-primary w-full"
            color="primary"
            block
            size="lg"
            disabled={
              this.props.registration_working
                || !this.state.un
                || !this.state.pw
                || !this.state.email
                || (this.state.pw !== this.state.pw_confirm)
                || !this.state.phone
                || !this.state.phone_country
                || !this.state.cc_valid
                || !this.checkPW()
                || !this.state.confirm_code_sms
            }
            onClick={() => this.onFormSubmit()}
          >
            Create an account
          </Button>
        </div>
      </div>
    )
  }
}

const PasswordCheckPopover = ({ has_min_chars, has_uppercase, has_number, has_symbol}) => (
  <div className="p-3 z-10 border absolute left-full top-1/2 w-96 translate-x-2 -translate-y-1/2 bg-white shadow-lg rounded">
    <div className="flex items-center gap-2">{has_min_chars ? <IconCheck className="stroke-green-700" /> : '•'} <span>Minimum 8 characters</span></div>
    <div className="flex items-center gap-2">{has_uppercase ? <IconCheck className="stroke-green-700" /> : '•'} <span>Contains at least 1 uppercase letter</span></div>
    <div className="flex items-center gap-2">{has_number ? <IconCheck className="stroke-green-700" /> : '•'} <span>Contains at least 1 number</span></div>
    <div className="flex items-center gap-2">{has_symbol ? <IconCheck className="stroke-green-700" /> : '•'} <span>Contains at least 1 symbol</span></div>
  </div>
)
export default withRouter(connect(
  state => ({
    registration_working: state.Auth.get("registration_working"),
    registration_data: state.Auth.get("registration_data"),
  }),
  {
    userRegister,
    userRegisterSMSSend,
  }
)(RegisterForm));
