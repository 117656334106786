import React, { Component} from "react";
import { connect } from "react-redux";
import { Button, DialogTitle, Field, Label } from "@headlessui/react";
import Modal from "../../components/Modal";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import shipmentActions from "../../redux/shipment/actions";
import moment from "moment-timezone";

const {
  shipmentActiveGetDeliveryWindow,
  shipmentActiveConfirmTransportationOptions,
} = shipmentActions;

class CompleteShipment extends Component {
  state = {
    ready_to_ship_window: {},
    transportation_option: {},
    delivery_window_option: {},
  }

  setStateCustom = (state, id, value) => {
    var data = {...this.state[state]}
    data[id] = value;
    this.setState({[state]: data})
  }

  getDeliveryWindow = () => {
    const { shipment_active } = this.props;
    const { ready_to_ship_window } = this.state;
    this.setState({
      transportation_option: {},
      delivery_window_option: {},
    })
    var data = {};
    Object.keys(ready_to_ship_window).forEach(key => {
      data[key] = `${moment(ready_to_ship_window[key]).format("YYYY-MM-DDTHH:MM")}Z`;
    })
    this.props.shipmentActiveGetDeliveryWindow({
      action: "get-transportation-options",
      shipment_id: shipment_active.id,
      data: {
        ready_to_ship_windows: data,
      }
    })
  }

  generateTransportationOptions = (id) => {
    const { shipment_active } = this.props;
    if(!shipment_active?.transportation_options){
      return [];
    }
    return shipment_active.transportation_options[id].map(item => {
      return {
        label: `${item?.carrier?.name}(${item?.carrier?.alphaCode})`,
        value: item?.transportationOptionId,
        data: item,
      }
    })
  }

  confirmTransportationOptions = () => {
    const { shipment_active } = this.props;
    const {
      transportation_option,
      delivery_window_option,
    } = this.state;
    var data = {};
    Object.keys(transportation_option).forEach(key => {
      data[key] = {
        transportation_option_id: transportation_option[key].value,
        delivery_window_id: delivery_window_option[key].value,
      };
    })

    this.props.shipmentActiveConfirmTransportationOptions(
      {
        action: "confirm-transportation-options",
        shipment_id: shipment_active.id,
        data: {
          options: data,
        }
      },
      {
        success: [
          {
            function: this.props.onClose,
            params: [],
          }
        ]
      }
    )
  }

  generateDeliveryWindowOptions = (id) => {
    const { shipment_active } = this.props;
    if(!shipment_active?.delivery_window_options){
      return [];
    }
    return shipment_active.delivery_window_options[id].map(item => {
      return {
        label: `[${item?.availabilityType}] ${moment(item?.startDate).format("LLL")}-${moment(item?.endDate).format("LLL")} - valid to: ${moment(item?.validUntil).format("LLL")}`,
        value: item?.deliveryWindowOptionId,
        data: item,
      }
    })
  }

  validateDeliveryOptionsData = (shipments) => {
    const {
      ready_to_ship_window,
    } = this.state;
    const shipment_ids = shipments.map(item => {return item.id.toString()});
    let valid = true;
    const rts_keys = Object.keys(ready_to_ship_window);
    shipment_ids.forEach(key => {
      if(!rts_keys.includes(key)){valid = false;}
    })
    return valid;
  }

  validateConfirmData = (shipments) => {
    const {
      delivery_window_option,
      transportation_option,
    } = this.state;
    const shipment_ids = shipments.map(item => {return item.id.toString()});
    let valid = true;
    const t_keys = Object.keys(transportation_option);
    const d_keys = Object.keys(delivery_window_option);
    shipment_ids.forEach(key => {
      if(!t_keys.includes(key)){valid = false;}
      if(!d_keys.includes(key)){valid = false;}
    })
    return valid;
  }

  render() {
    const {
      open,
      onClose,
      shipment_active_working,
      shipment_active,
    } = this.props
    const {
      ready_to_ship_window,
      transportation_option,
      delivery_window_option,
    } = this.state;

    const shipments = [shipment_active, ...shipment_active.related_shipments]

    return (
      <Modal open={open} onClose={onClose} size="2xl">
        <DialogTitle className="font-semibold text-lg mb-4">Complete Shipment</DialogTitle>
        <div className="grid grid-cols-3 gap-4">
          {shipments.map(item => (
          <div className="border px-3 py-2 rounded" key={`shipment-${item.id}`}>
            <div className="border-b py-1 px-2 -mx-3 -mt-2 mb-2 bg-slate-100">
              <h4 className="font-bold text-slate-700">{item.shipment_confirmation_id}{item.transportation_options_confirmed ? "(CONFIRMED)" : null}</h4>
            </div>
            <Field className="field">
              <Label>Delivery Window</Label>
              <div className="flex items-start gap-4">
                <div>
                  <DatePicker
                    className="w-60"
                    selected={ready_to_ship_window[item.id]
                      ? ready_to_ship_window[item.id]
                      : null
                    }
                    popperPlacement="bottom-start"
                    onChange={selected => {
                      this.setStateCustom("ready_to_ship_window", item.id, selected)
                    }}
                    disabled={item.transportation_options_confirmed}
                  />
                  <small className="block text-slate-500">Choose start date of delivery window</small>
                </div>
              </div>
            </Field>
            <Field className="field mt-4">
              <Label>Select Shipping Carrier</Label>
              <Select
                options={this.generateTransportationOptions(item.id)}
                onChange={(e) => this.setStateCustom("transportation_option", item.id, e)}
                selected={transportation_option[item.id]
                  ? transportation_option[item.id]
                  : null
                }
                disabled={shipment_active_working || item.transportation_options_confirmed}
              />
            </Field>
            {transportation_option[item.id]?.data?.preconditions?.includes("CONFIRMED_DELIVERY_WINDOW") &&
              <Field className="field mt-4">
                <Label>Confirm Delivery Window</Label>
                <Select
                  options={this.generateDeliveryWindowOptions(item.id)}
                  onChange={(e) => this.setStateCustom("delivery_window_option", item.id, e)}
                  selected={delivery_window_option[item.id]
                    ? delivery_window_option[item.id]
                    : null
                  }
                  disabled={shipment_active_working || item.transportation_options_confirmed}
                />
              </Field>
            }
          </div>
          ))}
        </div>
        <div className="mt-4 flex justify-between gap-4">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <div className="flex gap-4">
            <Button
              onClick={() => this.getDeliveryWindow()}
              disabled={shipment_active_working || !this.validateDeliveryOptionsData(shipments)}
              className="btn-secondary"
            >Get delivery options</Button>
            <Button
              className="btn-primary"
              onClick={() => this.confirmTransportationOptions()}
              disabled={shipment_active_working || !this.validateConfirmData(shipments)}
            >Confirm</Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    shipment_active_working: state.Shipment.get("shipment_active_working"),
    shipment_active: state.Shipment.get("shipment_active"),
  }),
  {
    shipmentActiveGetDeliveryWindow,
    shipmentActiveConfirmTransportationOptions,
  }
)(CompleteShipment);
